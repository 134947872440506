.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding: 0 !important;
}

* {
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
}

body {
  font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #f9f9f9;
}

body[freeze] {
  overflow: hidden;
}

a {
  color: #4d4d4d;
}

a:focus,
a:hover {
  text-decoration: none;
}

.spacer {
  margin-bottom: 700px;
}

.float_wrapper::after {
  content: '';
  display: table;
  clear: both;
}

.toggle_window,
.rel {
  display: none !important;
}

.toggle_window[active],
.rel.active {
  display: block !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #636363;
  border: 1.5px solid #f5f5f5;
  border-top: 0;
  border-bottom: oldlace;
  border-radius: 12px;
}


/* ::-webkit-scrollbar-thumb:active {} */


.relative_block {
  position: relative;
  display: block;
  width: 100%;
}

.card {
  position: relative;
  display: block;
  width: 100%;
  min-height: 1px;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.card.layer1 {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.card.layer2 {
  box-shadow: 0px 10px 20px -15px rgb(0 0 0 / 50%);
  border-radius: 12px;
}


.button.lazy {
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.button:hover,
.button:focus {
  outline: none;
}

.button.small {
  font-size: 10px;
  padding: 0.6em 2.5em;
}

.button.ghost {
  background: #f5f5f5;
  color: #000;
}



.button[data-state] {
  padding-left: 4.5em;
  padding-right: 2.5em;
}

.button[data-state="loading"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 0.6em);
  left: 1em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  animation-name: loading_rotate;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

#statusinfo{
  position: relative;
  top: -13rem;
  left: 83%;
}



#office  {
  position: absolute;
  top: -14px !important;
  left: 0;
  display: block;
  width: 96px !important;
  height: 80px !important;
  background: #000;
  border-radius: 0% !important;
  border: 3px solid #03a9f4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 5px 10px #ffff;
}









@keyframes loading_rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button[data-state="done"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  left: 1em;
  width: 16px;
  height: 7px;
  border: 2px solid #fff;
  border-right: 0;
  border-top: 0;
  transform: rotate(-40deg);
}

.button[data-state="failed"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 7.5px);
  left: 1em;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* background: url('../../img/icons/close_white.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card_wrapper {
  padding: 0 15px;
}

.input_group {
  position: relative;
  display: block;
  width: 100%;
  margin: 18px 0px;
  padding: 0px 10px;
  /* z-index: 1; */
}

.input_group label {
  position: absolute;
  left: 10px;
  top: 13px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: rgb(99, 99, 99);
  margin: 0;
  text-transform: capitalize;
  transition: all 0.15s ease-in;
  z-index: 0;
}

.input_group .fake_label {
  margin-bottom: 0;
  margin-top: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: #42434a;
}

.input_group .input {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  font-weight: 400;
  color: #585858;
  background: 0;
  z-index: 1;
}

.input_group .input:focus {
  outline: 0;
}

.input_group span.highlight::after,
.input_group span.highlight::before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 0%;
  background: #066aff;
  transition: all 0.1s linear;
  display: block;
  z-index: 1;
}

span.highlight::before {
  right: 50%;
}

span.highlight::after {
  left: 50%;
}

.input:focus~span.highlight::after,
.input:focus~span.highlight::before {
  width: calc(50% - 10px);
}

.input:focus~label,
.input:valid~label {
  top: -13px;
  color: rgb(135, 135, 135);
  font-size: 12px;
}

.input_group .input[invalid]~span.highlight::after,
.input_group .input[invalid]~span.highlight::before {
  background: #d83a2f;
}

.input[invalid]~span.highlight::after,
.input[invalid]~span.highlight::before {
  width: calc(50% - 10px);
}

.input[valid]~i.style::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 14px;
  right: 12px;
  background: 0;
  border: 1px solid #009688;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  bottom: 12px;
  z-index: 1;
}

.instruct {
  position: absolute;
  display: none;
  list-style: none;
  margin-top: 5px;
  width: auto;
  top: 100%;
  left: 10px;
  right: 10px;
  background: #fff;
  z-index: 11;
  border-radius: 3px;
  padding-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}

.instruct_right .instruct {
  right: initial;
  left: calc(100% + 20px);
}

.instruct::before {
  content: '';
  position: absolute;
  right: -7px;
  top: 10px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  transform: rotate(-45deg);
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
}

.instruct_right .instruct::before {
  display: none;
}

.instruct span::after {
  content: '';
  position: absolute;
  top: 0;
  left: -6px;
  width: 2px;
  background: rgba(0, 0, 0, 0.21);
  height: 100%;
  display: block;
}

.instruct span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  z-index: 1;
}

.instruct li:first-child {
  margin-top: 0;
}

.instruct li {
  position: relative;
  display: block;
  margin-top: 10px;
  font-size: 13px;
  color: #444;
  z-index: 2;
  font-weight: 400;
}

.instruct li::first-letter {
  text-transform: uppercase;
}

.instruct li::before {
  content: '';
  position: absolute;
  top: calc(50% - 7.5px);
  left: -23px;
  width: 16px;
  height: 16px;
  display: block;
  background: 0;
  border: 2px solid rgba(0, 0, 0, 0.21);
  border-radius: 50%;
  background: #fff;
}

.instruct li::after {
  content: '';
  position: absolute;
  top: calc(50% - 3.5px);
  left: -19px;
  width: 8px;
  height: 8px;
  display: none;
  background: #4CAF50;
  border-radius: 50%;
}

.instruct li[checked]::after {
  display: block;
}

.input:focus~ul.instruct {
  display: block;
}

.checkbox {
  position: relative;
  text-align: left;
  width: 100%;
  height: 18px;
  margin: 0;
}

.checkbox::after {
  content: '';
  display: table;
  clear: both;
}

.checkbox.checkbox-inline {
  display: inline-block;
}

.checkbox label {
  cursor: pointer;
  z-index: 1;
  padding-left: 24px;
  margin: 0;
  float: left;
  margin-top: -1px;
  font-weight: 400;
  top: 0px;
  left: 0;
  width: 100%;
}

.checkbox label:before,
.checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
}

.checkbox label:before {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s linear;
  top: -1px;
  left: 0;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox input[type="checkbox"]:checked+label:before {
  background: #2196F3;
  border: none;
}

.checkbox input[type="checkbox"]:checked+label:after {
  position: absolute;
  transform: rotate(-45deg);
  top: 5px;
  left: 4px;
  width: 11px;
  height: 6px;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
}

.checkbox input[type="checkbox"]:disabled+label:before {
  border-color: rgba(0, 0, 0, 0.26);
}

.checkbox input[type="checkbox"]:disabled:checked+label:before {
  background: rgba(0, 0, 0, 0.26);
}

p.input_guide {
  margin: 0;
  position: relative;
  top: -10px;
  padding: 0px 10px;
  color: #585858;
  font-size: 13px;
}

p.input_guide.input_error {
  color: #ff5722 !important;
  font-weight: 500;
  font-size: 13px;
  padding-left: 0;
}

label.static {
  position: relative;
  left: 0;
  margin-bottom: 10px;
  text-align: start;
}

.infobox {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.infobox_wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.infobox_wrapper.center {
  text-align: center;
}

.infobox_wrapper.left {
  text-align: left;
}

.infobox_wrapper.right {
  text-align: right;
}

.infobox_wrapper .infobox {
  display: inline-block;
  width: auto;
}

.infobox::after {
  content: '';
  display: table;
  clear: both;
}

.infobox .item {
  position: relative;
  display: inline-block;
  float: left;
  min-height: auto;
  margin: 0px 7px;
}

.infobox .item .icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
  width: 32px;
  height: 32px;
}

.infobox .item .text {
  position: relative;
  padding-left: 35px;
  display: block;
  width: 100%;
}

.infobox .item .button {
  margin: 0;
  top: -0.3em;
}

.button .icon {
  left: 16px !important;
  width: 14px !important;
}

.button .text {
  color: initial;
}

.icon_button {
  position: relative;
  display: inline-block;
  width: auto;
  cursor: pointer;
  padding: 0;
}

.icon_button .icon {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  background: #000;
}

.infobox_big {
  position: relative;
  display: block;
  width: 100%;
}

.infobox_big .item {
  text-align: center;
}

.infobox_big .col-sm-3+.col-sm-3 .item {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.infobox_big .item .icon {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  margin-bottom: 10px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url('../img/logo.png'); */
}

.infobox_big .item .title {
  position: relative;
  display: block;
  font-size: 13px;
  color: #191818;
  font-weight: 600;
  text-transform: capitalize;
  word-spacing: 2px;
}

.infobox_big .item .excerpt {
  position: relative;
  display: block;
  color: #616161;
  font-size: 12.5px;
}

.gutter {
  padding: 10px;
}

ul.list {
  list-style: none;
  padding-left: 0;
}

ul.list li.title {
  position: relative;
  margin-bottom: 12px;
  display: block;
  text-transform: capitalize;
  font-weight: 800;
  color: #444;
  padding-left: 0;
}

ul.list li {
  position: relative;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
  color: #777;
  font-size: 13px;
  padding-left: 12px;
}

ul.list.floated li::after {
  content: '';
  display: table;
  clear: both;
}

ul.list.far li {
  margin-top: 12px;
  margin-bottom: 12px;
}

.drop_menu {
  display: none;
}

.drop_menu.active,
.drpdwn[dropdown_active] .drop_menu,
.drop_menu[active] {
  display: block;
}

.drpdwn .status_ico {
  position: absolute;
  right: 0;
  top: 3px;
  width: 9px;
  height: 9px;
  /* background: url('../img/sidebar/add.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.65;
}

.drpdwn[dropdown_active] .status_ico {
  /* background: url('../img/sidebar/substract.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

*[data-toggle] {
  cursor: pointer;
}

.pagination_wrapper {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
}

.pagination {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 10px 0;
}

.pagination li {
  border-radius: 3px;
  min-width: 28px;
  padding: 2px 7px;
  display: inline-block;
  background: #ffffff;
  font-size: 13px;
  font-weight: 600;
  color: #4f525a;
  margin: 3px 3px;
  box-shadow: 0px 1px 0px 0px #00000029;
  cursor: pointer;
}

.pagination li[checked] {
  background: #2196f3;
  color: #fff;
}

.pagination .control {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0px 8px;
  padding: 0px 8px;
  color: #4CAF50;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: rgb(255, 255, 255) !important;
}

textarea.input {
  padding: 10px 0px;
}

.tabs .tabs_control {
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0 3px;
  margin: 0;
  background: #e8e8e8;
}

.tabs .pills_container::after {
  content: '';
  display: table;
  clear: both;
}

.tabs .tabs_control li {
  position: relative;
  display: inline-block;
  padding: 12px 4px;
  background: #e8e8e8;
  color: #2b2c36;
  margin: 0;
  float: left;
  cursor: pointer;
  border-right: 1px solid #ffffff;
  text-align: center;
  width: 25%;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
}

.tabs .tabs_control li:last-child {
  border: 0;
}

.tabs .tabs_collection {
  position: relative;
  display: block;
  width: 100%;
  min-height: 200px;
  background: #ffffff;
  z-index: 1;
}

.tabs .tabs_collection .content_wrapper {
  display: none;
  padding: 15px;
}

.tabs .tabs_collection .content_window[active] .content_wrapper {
  display: block;
}

.tabs .tabs_control li[active] {
  background: #ffffff;
  color: #2b2c36;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
  z-index: 1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 500;
  font-size: 13px;
  top: 3px;
}

.tabs .tabs_control li[hovered] {
  width: auto !important;
  position: relative;
  padding: 12px 10px;
  z-index: 1;
  background: #000;
  color: #fff;
}

.tabs .tool_tip {
  position: absolute;
  top: -5px;
  left: 15px;
  width: auto;
  height: auto;
  border-radius: 3px;
  z-index: 9;
  background: #2b2c36;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
  display: none;
  cursor: pointer;
}

.tabs .tool_tip b {
  position: relative;
  width: auto;
  height: auto;
  border-radius: 3px;
  padding: 7px;
  z-index: 5;
  background: #2b2c36;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  z-index: 1;
}

.tabs .tool_tip::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background: #2b2c36;
  transform: rotate(45deg);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
}

.tabs {
  overflow: visible;
}

.tool_tip[data-state="show"] {
  display: inline-block;
}

.tabs .pills_container {
  position: relative;
  overflow: hidden;
}

.tabs .tabs_control.progresstype li[active] {
  background: 0;
  box-shadow: none;
  top: 0px;
  font-size: 12px;
  font-weight: 500;
}

.tabs .tabs_control.progresstype {
  background: #fff;
  overflow: visible;
  padding: 10px;
}

.tabs .tabs_control.progresstype .pills_container {
  height: 60px;
}

.tabs .tabs_control.progresstype li {
  background: #fff;
  padding-bottom: 12px;
  overflow: visible;
}

.tabs .tabs_control.progresstype li::before {
  content: '';
  width: 20px;
  height: 20px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  z-index: 1;
  border-radius: 50%;
  border: 2px solid #ccc;
}

.tabs .tabs_control.progresstype li .progress {
  position: absolute;
  left: 0;
  bottom: -31px;
  width: 100%;
  height: 2px;
  background: #ccc;
}

.tabs .tabs_control.progresstype li::after {
  content: '';
  position: absolute;
  left: calc(50% - 6px);
  bottom: -16px;
  width: 12px;
  height: 12px;
  display: block;
  z-index: 1;
  border-radius: 50%;
}

.tabs .tabs_control.progresstype li[active][completed]::after {
  content: '';
  position: absolute;
  left: calc(50% - 6px);
  bottom: -16px;
  width: 12px;
  height: 12px;
  display: block;
  z-index: 1;
  border-radius: 50%;
  border: 0;
}

.tabs .tabs_control.progresstype li[active]::after {
  background: #2196f3;
}

.tabs .tabs_control.progresstype li[completed]::after {
  width: 12px;
  height: 6px;
  border-radius: 0;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  bottom: -12px;
}

.tabs .tabs_control.progresstype li[active]::before,
.tabs .tabs_control.progresstype li[completed]::before {
  border: 2px solid #2196f3;
}

.tabs .tabs_control.progresstype li[completed]::before {
  background: #8BC34A;
}

.tabs .tabs_control.progresstype li[completed][active]::before {
  background: #fff;
}

.tabs .tabs_control.progresstype li[active] .progress,
.tabs .tabs_control.progresstype li[completed] .progress {
  background: #2196f3;
}

.noselect,
.toggle,
.tabs .tabs_control li,
.tool_tip,
.button,
a,
.checkbox label,
.nav li {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

#notification_holder .brief {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  min-height: 30px;
  background: #ccc;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  animation-name: show_notification;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
}

@keyframes show_notification {
  0% {
    opacity: 0;
    top: 15px;
  }

  80% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
}

#notification_holder .brief.success {
  background: #4CAF50;
}

#notification_holder .brief.message {
  background: #2196F3;
}

#notification_holder .brief.warning {
  background: #de8400;
}

#notification_holder .brief.error {
  background: #FF5722;
}

#notification_holder .brief .icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 14px;
  height: 14px;
  background: #ccc;
  display: block;
}

#notification_holder .brief .text {
  position: relative;
  text-align: left;
  display: block;
  padding-left: 30px;
  color: #fff;
  font-size: 13px;
}

#notification_holder .brief.success .icon {
  /* background: url('../../img/icons/tick.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#notification_holder .brief.error .icon {
  /* background: url('../../img/icons/close_white.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 11px;
}

#notification_holder .brief.warning .icon {
  /* background: url('../../img/icons/danger.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}

#notification_holder .brief.message .icon {
  /* background: url('../../img/icons/megaphone.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

#notification_holder.temp {
  position: fixed;
  width: 300px;
  z-index: 99;
  bottom: 20px;
  left: 10px;
  display: none;
}

#notification_holder.temp[checked] {
  display: block;
}

#notification_holder.temp .brief[getting_out] {
  animation-name: notification_temp;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes notification_temp {
  0% {
    opacity: 1;
    display: block;
  }

  90% {
    opacity: 1;
    left: 0;
  }

  100% {
    opacity: 0;
    left: -100px;
  }
}

.loading.big {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.4);
  display: none;
}

.loading.small {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  z-index: 9;
  background: rgba(255, 255, 255, 0.9);
  display: none;
}

.loading[data-state=loading] {
  display: block;
}

.loading[data-state=fade] {
  display: block;
  opacity: 0.5;
}

.loading[data-state=loading]::after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  background: 0;
  border: 3px solid #673ab7;
  border-left: 3px solid transparent;
  animation-name: loading_rotate;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.loading.small[data-state=loading]::after {
  top: 168px;
  left: calc(50% - 13px);
  width: 26px;
  height: 26px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
}


/* .anchor{
  box-shadow: 1px 1px 1px #000;
} */

.anchor.warning {
  background: #ff9800;
}

.anchor.success {
  background: #4caf50;
}

.anchor.primary {
  background: #2196f3;
}

.anchor.ghost {
  background: #fff;
  color: #000;
}

.anchor:hover,
.anchor:focus,
.anchor:active {
  text-decoration: none;
  color: #fff;
}

.anchor.ghost:hover,
.anchor.ghost:focus,
.anchor.ghost:active {
  color: #000;
}

.progress_bar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  background: #d2d2d2;
}

.progress_bar span.progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  overflow: initial;
  background: #2196F3;
  transition: all 0.1s linear;
}

.ajax_search .drop_menu {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-top: 5px;
}

.ajax_search .drop_menu ul {
  position: relative;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.ajax_search .drop_menu ul li {
  position: relative;
  margin: 6px 0px;
  padding: 3px;
  font-size: 14px;
  cursor: pointer;
  z-index: 1;
}

.ajax_search .drop_menu ul li:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  bottom: 0;
  right: -10px;
  background: #efefef;
  z-index: -1;
}

.ajax_search .icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 15px;
  height: 15px;
  /* background: url('../../img/icons/add.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(45deg);
  z-index: 1;
  cursor: pointer;
}

.no_marg {
  margin: 0 !important;
  padding: 0;
}

.tags {
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags::after {
  content: '';
  display: table;
  clear: both;
}

.tags li {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  padding-right: 25px;
  background: #f1f1f1;
  margin: 2px;
  float: left;
  border-radius: 15px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
}

.tags li span {
  position: absolute;
  right: 4px;
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  background: #c3c3c3 !important;
  border-radius: 50%;
  cursor: pointer;
}

.tags li span::after {
  content: '';
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  width: 8px;
  height: 8px;
  /* background: url('../../img/icons/close.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* modals */
.modal {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 11;
  display: none;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.modal[active] {
  display: block;
}

.modal .icon.cross {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.rel {
  display: none;
}

.rel.active {
  display: block;
}

.pull-left {
  position: relative;
  float: left;
}

.pull-right {
  position: relative;
  float: right;
}

/* Ripple effect */
.ripple {
  overflow: hidden;
}

.ripple::before {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background: #ccc;
  z-index: 5;
  display: none;
}

.ripple[ripple]::before,
.ripple:active::before {
  animation-name: ripple;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  display: block;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: scale(30);
    opacity: 0;
  }
}