#sidebar {
    position: fixed;
    top: 0;
    left: -200px;
    bottom: 0;
    width: 300px;
    z-index: 10;
    transition: all 0.15s ease-in;
    display: flex;
}

#sidebar.active {
    left: 0;
}
#sidebar.is_active{
    display: none !important;
}

#sidebar .card {
    overflow-y: auto;
    height: 100vh;
    padding: 10px;
    padding-bottom: 50px;
    background: #0a2840;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 10px 0px 10px 0 rgb(0 0 0 / 25%);
    border: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

#sidebar hr {
    margin: 0px 0px;
    opacity: 0.1;
}

#sidebar .items {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none !important;
    /* border: 2px blue; */
    /* box-shadow: 0 2px 200px blue; */
    border-top: solid 2px #f2f2f20f;
    padding: 12px 10px;
}
#sidebar .items:hover {
    background: #2196f3;
    transition: .5s ease-in-out;
    padding-left: 20px;
}
#sidebar .items:active{
    background: #2196f3;
}

#sidebar .nav .items .title {
    position: relative;
    /* display: flex; */
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    color: #ffffff;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-decoration: none;
}

#sidebar .nav ul,
#sidebar .nav ul li {
    margin: 0;
    padding-left: 20px;
}

#sidebar .nav ul li a {
    text-decoration: none;
    padding: 5px;
    width: 100%;
    display: block;
}

#sidebar .nav span.icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 15px;
    opacity: 1;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
    filter: invert(1);
}

#sidebar .nav ul li a span.text {
    font-size: 13px;
    font-weight: 400;
    color: #6d6d6d;
    text-transform: capitalize;
    word-spacing: 1px;
}

#sidebar .infobox_big .items,
#sidebar .infobox_big .items .title,
#sidebar .infobox_big .items .excerpt {
    text-align: left;
    color: #ffffff;
    font-weight: 700;
    transform: scale(1.05);
    transform-origin: left;
    overflow: hidden;
    text-decoration: none;
    display: block;
}

#sidebar .infobox_big.avatar {
    /* padding-left: 12px; */
}

#sidebar .icon.cross {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    display: block;
    z-index: 1;
    cursor: pointer;
}

#sidebar .nav ul {
    margin-bottom: 12px;
}

#sidebar .drpdwn .status_ico {
    top: 17px;
}

#sidebar .nav {
    margin-top: 45px;
}

#content {
    position: relative;
    display: block;
    padding: 0px 10px;
    left: 300px;
    width: calc(100% - 300px);
}

.title{
    text-decoration: none;
}

.autarimage{
    position: relative;
    display: flex !important;
}

.items{
    display: flex;

}
.mobile-icon{
    position: relative;
    margin: 10px;
    display: none;
    margin: 10px;
}

.mobilemenu{
   position: relative;
   float: right;
   margin: 10px;
   z-index: 100;
   display: none;
}
.nav-icon{

    display: none;
}
.active{
    display: block;
}
.providerSelector {
    height: 400px !important;
    overflow-y: scroll;
}
.providerSelector .card{
    height: 120px;
}
.providerSelector .card-title, button{
    margin-top: 15px;
}
.providerSelectorImg{
    max-width: 80px;
    border-radius: 50%;
}
@media only screen and (min-width:320px) and (max-width:770px){
    .nav-icon{
        position: relative;
        padding-left: 140px;
        left: 13vh;
        display: block;
    }
    
    .mobile-icon{
        position: relative;
        margin: 10px;
        display: block;
    }

    #sidebar{
        display: none;
        left: 0;
    }
 
    .mobilemenu{
        position: relative;
        float: left;
        margin: 10px;
        z-index: 100;
        display: block;
     }
    
}
