/* #alternate_post .heading>p {
    padding: 55px !important;
    padding-top: 0px !important;
 
} */


/* #storypara>p{
    text-align: center;
    justify-content: center;
    padding-left: 50px;
    
} */

/* #storypara {
    justify-content: center !important;
    padding: 55px !important;
    padding-top: 0px !important;
  }

  .className_brief.item.card.layer1 {
    width: 95%;
    box-shadow: inset 0 -5px 2% #aaaa !important;
    border: none ;
    padding: 5px;
    margin-left: -10vh;
}

.heading>h3{
    justify-content: center;
    align-items: center;
} */



#storypara {
    justify-content: center !important;
    padding: 55px !important;
    padding-top: 0px !important;
  }


  .true-item{
    /* margin-left: -15px; */
  }

  @media (max-width:992px) {
    .true-item{
        margin-left: -10px;
      }
  }