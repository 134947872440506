#gift_card_container_main {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin: auto;
	justify-content: center;
	align-items: center;
	align-content: center;
	/* margin-bottom: 20%; */
}

.Gift_card_container_buy {
	flex-basis: calc(33.33% - 20px); /* Three cards per row with padding */
	box-sizing: border-box;
	padding: 10px;
}




.gift_card_buy_item {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background: #ffffffb8;
}
.gift_image img {
    height: 160px;
    width: 100%;
    border-radius: 10px 10px 0 0;
}

.image_container_gift_card img {
	width: 100%;
	height: 200px;
}

.content_container_gift_card {
	justify-content: flex-start;
	text-align: justify;
	margin: 0 5px;
	padding: 15px;
}

.content_container_gift_card h3 {
	font-size: 15px;
}

.content_container_gift_card_para {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 5px 0;
	font-weight: 500;
	align-items: center;
}
.content_container_gift_card_para > p {
	margin: 0;
	font-size: 15px;
}
.content_container_gift_card_dis {
	display: flex;
	justify-content: space-between;
	margin-top: -10px;
}
.description {
	font-size: 13px;
	opacity: 0.7;
}
#Buy_gift_card {
    padding: 4px 10px;
    border: none;
    border-radius: 10px;
    background-color: #2196f3;
    color: #ffff;
    /* margin-bottom: 10px; */
	margin: 0px 0 10px 0;
    font-size: 14px;
}

/* Media query for mobile devices */
@media screen and (max-width: 982px) {
	.Gift_card_container_buy {
		width: 100%;
		/* Set width to 100% for 1 card in a row on mobile */
	}
	.Gift_card_container_buy {
		flex-basis: calc(50% - 20px); /* Two cards per row with padding */
	}
	#Buy_gift_card {
		padding: 5px 4px;
		border: none;
		border-radius: 8px;
		background-color: #2196f3;
		color: #ffff;
		/* margin-bottom: 10px; */
		margin: 0px 0 10px 0;
		font-size: 11px;
	}
}



@media screen and (max-width: 768px) {
	.Gift_card_container_buy {
		flex-basis: calc(100% - 20px); /* One card per row with padding */
		
	}
}
