/* BookingModal.css */

.booking-modal-container {
    background: url(../../../../assets/img/9496304.png);
    background-size: 100% 100%;
    background-attachment: fixed;
    width: 80%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 100px;
    right: 0;
    left: 20%
}

.booking-card-heading {
    text-align: center;
    justify-content: center;

}



#booking-card-content h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    justify-content: center;


}

#booking-card-content h3 {
    font-size: 18px;
    margin: 20px;
    font-weight: 700;
    color: #333;
    text-align: center;
    justify-content: center;

}

#booking-card-content p {
    font-size: 12px;
    /* margin-bottom: 20px; */
    color: #333;
    text-align: center;
    justify-content: center;

}

.booking-modal-inner {
    margin: 0 20px;


}

.booking-no-data {
    color: #162b3c;
}

.booking-list-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.booking-card {
    /* flex: 1 1 calc(50% - 10px); */
    width: 48%;
    margin: auto;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 3px 7px 8px -2px rgb(0 0 0 / 15%);
    transition: all 0.3s ease-in-out;
    border-radius: 12px;
    border: 1px solid #ebebeb;


}

.booking-card-content {
    padding: 10px;
}






.booking-card:hover {
    transform: scale(1.02);
}

.booking-card-content {
    padding: 15px;
}

.booking-card-ripple {
    display: block;
}

.booking-card-time {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.booking-card-time-scheduled {
    font-size: 12px;
}

.booking-card-time-date {
    font-size: 12px;
    color: #666;
}

.booking-card-profile {
    display: flex;
    align-items: center;
}

.booking-card-avatar {
    margin-right: 15px;
}

.booking-card-avatar img {
    border-radius: 50%;
}

.booking-card-text {
    flex: 1;
}

.booking-card-text h3 {
    font-size: 14px;
}

.booking-card-text p {
    font-size: 12px;
}

.booking-card-addons {
    margin-top: 10px;
}

.booking-card-addons h3 {
    margin-bottom: 10px;
    font-size: 14px;
}

.booking-card-addons p {
    margin: 0;
    font-size: 12px;
}

.booking-details {
    padding: 15px;
    background: #fff;
}

.booking-details-address {
    margin-bottom: 10px;
}

.booking-details-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.booking-details-button:hover {
    background-color: #0056b3;
}

.booking-details-host {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
}

.booking-details-billing {
    display: flex;
    justify-content: space-between;
}

.booking-details-billing-amount {
    font-size: 16px;
}

.booking-details-billing-status {
    font-size: 16px;
    color: #007bff;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination-button {
    border: none;
    background-color: #f0f0f0;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-button.active {
    background-color: #007bff;
    color: white;
}

.booking-details-address {
    margin-bottom: 10px;
    text-align: center;
}

#review-rating-button {
    cursor: "pointer";
    padding: 5px 10px;
    font-size: 12px;

}

/* // pagination container */
.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    /* equivalent to mt-4 */
}

.pagination-list {
    display: flex;
    gap: 0.5rem;
    /* equivalent to space-x-2 */
}

.pagination-button {
    padding: 0.5rem 0.75rem;
    /* equivalent to px-3 py-2 */
    font-size: 12px;
    /* equivalent to text-[12px] */
    border-radius: 0.375rem;
    /* equivalent to rounded */
    background-color: #e2e8f0;
    /* equivalent to bg-gray-300 */
    color: #4a5568;
    /* equivalent to text-gray-700 */
    transition: background-color 0.2s ease-in-out;
}

.pagination-button:hover {
    background-color: #cbd5e0;
    /* equivalent to hover:bg-gray-400 */
}

.pagination-button.active {
    background-color: #3b82f6;
    /* equivalent to bg-blue-500 */
    color: #fff;
    /* equivalent to text-white */
}


@media (max-width: 480px) {

    .booking-card-profile {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
        /* Align other profile items to the start */
        margin-top: 0 !important;
        /* Reset any top margin if needed */
    }

    .booking-card-profile {
        display: flex;
        align-items: none !important;
        margin-top: -20px !important;
    }

    .booking-details-address {
        margin-bottom: 10px;
        text-align: center;
    }

    .booking-card-time {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;
        /* Ensure the width is 100% for proper spacing */
    }

    .booking-card-avatar {
        display: flex;
        /* Make sure this is a flex container */
        justify-content: center;
        /* Center avatar horizontally within its container */
        align-items: center;
        /* Center avatar vertically within its container */
        /* margin-bottom: 10px; */
        /* Add space if needed */
        width: 100%;
        /* Ensure it takes full width to center the avatar properly */
    }

    .booking-card-avatar img {
        border-radius: 50%;
        width: 80px !important;
        height: 80px !important;
    }

    .booking-card-addons {
        margin-top: 10px;
        display: flex;
        /* flex-direction: row; */
        justify-content: space-between !important;
        width: 100%;
    }



    .rating-review-container {
        position: absolute !important;
        right: 0 !important;
        margin: -18px 36px 0px 0 !important;
    }
    .booking-card-text {
        flex: 1;
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }
    .booking-card-text h3 {
        font-size: 14px;
        
    }

    .booking-card-text p {
        font-size: 12px;
    }

    .booking-card {
        flex: 1 1 100%;
    }

    #review-rating-button {
        cursor: "pointer";
        padding: 3px 8px !important;
        font-size: 9px !important;
        margin: 5px !important;
    }
}