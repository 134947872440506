/* ModalCard.css */

#donwload_page_container{
    margin: 80px 0;
}
.about-image-card {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
}
.image-card {
    width: 100% !important;
    height: 100%;
    /* height: 300px; */
    background-size: cover;
    background-position: center;
    border-radius: 7px;
}

.service-image-card{
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
}

.memberhsip-image-card{
    width: 400px;
    height: 400px;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
}
.content-right {
    padding: 20px;
}


.title-card {
    margin: 0 0 10px;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 700;
    color: #263d4a;
}

.description-card {
    margin: 0 0 20px;
    font-size: 16px;
}

.button-card {
    display: flex;
    flex-direction: row;
    /* gap: 10px; */

}


#downloadimage {
    width: 160px;
    padding: 8px;
}

.provider_background {
   
    background: #d6edff;
    /* display: flex; */
    padding: 40px;

}
