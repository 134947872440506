.heading h3 {
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 800;
    color: #0f1538;
    text-align: center;
    justify-content: center;
    padding-left: 20vh;
}

.sign_in_form {
    height: 70%;
    text-align: left;
}

.sign_up_page {
    padding: 0 !important;
    margin-top: -20px !important;
}

.sign_up_form {
    background: white;
    z-index: 1;
    border-radius: 10px;
    padding: 10px;
    height: auto;
}