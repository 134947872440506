.PaymentForm {
    /* top: 200px; */
    width: 60%;
    margin: auto;
    align-items: center;
    padding: 0;
    background-color: white;
    border-radius: 10px;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
    border: solid 1px #d9d9d9;
}
.successful {
  color: green;
}

.PaymentForm p {
  margin: 2px;
}

.PaymentForm .head {
  text-align: center !important;
  width: 100% !important;
}

.PaymentForm p {
  text-align: left;
}

.failed {
  color: red;
}
.space {
  margin-top: 30px !important;
}
.receipt_head {
  height: 100px;
  background: #023d70;
  position: relative;
  border-radius: 10px 10px 0 0;
}
.receipt_footer {
  /* background: #a9d7ff; */
  position: relative;
  border-radius: 0 0 10px 10px;
  text-align: center;
  padding: 10px;
}
.receipt_footer p{
  text-align: center;
}

.receipt_head img {
  max-width: 300px;
}
.reciept_body
{
  padding: 20px;
}
.reciept_body p{
  word-wrap: break-word;
  line-height: 25px;
}
.receipt_logo {
  text-align: center;
  filter: saturate(0);
  background: grey;
  max-width: 175px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 50px;
  opacity: 0.3;
}

  @media (max-width:992px) {
    .PaymentForm {
        /* top: 200px; */
        width: 90%;
        margin: auto;
        align-items: center;
        padding: 0;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
        margin-top: 100px;
        margin-bottom: 100px;
        /* box shadow */
      }
  }