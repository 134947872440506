:root {
  --primary: #2196f3;
  --primaryHover: #2196f3;
  --colourfacebook: #4267b2;
  --colourwhatsapp: #075e54;
  --colourinstagram: #e95950;
  --colourtwitter: #00acee;
}

/* body {
  font-family: "Comfortaa", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
} */
.menu-icon {
  margin-right: 15px; /* Space between icon and text */
  color: #ffff; /* Customize color */
  font-size: 18px; /* Adjust size as needed */
}


.menu-item {
  display: flex;
  align-items: center;
  padding: 12px 15px; /* Add padding for better layout */
  font-size: 16px; /* Adjust text size */
  color: #333; /* Text color */
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.menu-item:hover {
  background-color: #f0f0f0; /* Background on hover */
}

.btn {
  transition: all 0.15s ease-in;
  border-radius: 25px;
}

.btn:hover,
.edit:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 10px -5px rgb(0 0 0 / 25%);
}

.facebook {
  color: var(--colourfacebook);
}

.whatsapp {
  color: var(--colourwhatsapp);
}

.instagram {
  color: var(--colourinstagram);
}

.twitter {
  color: var(--colourtwitter);
}

.phone {
  color: #2600cf;
}

.btn-primary {
  background: var(--primary);
  border: 2px solid #00e2ff;
}

.btn-primary:hover {
  background-color: var(--primaryHover) !important;
}

.nofillbtn {
  background: none;
  color: var(--primary);
  border-color: var(--primary);
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
}

/* .tags-input:focus-within {
  border: 1px solid #0052cc;
} */
.tags-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tags {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}

/* .tag .tag-title {
  margin-top: 3px;
} */
.tags .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 12px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

.nav-link {
  font-size: 1.1rem;
}

.img-box {
  width: 250px;
  height: 250px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.edit {
  position: absolute;
  transition: all 0.15s ease-in;
  background: white;
  border-radius: 50%;
  padding: 5px;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.profile-edit {
  right: 20px;
  top: 26px;
}

.personal-box {
  height: 100%;
}

.box {
  padding: 15px 15px;
  border: 2px solid var(--primary);
  border-radius: 8px;
  position: relative;
}

.personal-info-title {
  color: var(--primary);
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.skill-item {
  border-radius: 20px;
  border: 2px solid var(--primary);
  display: inline-block;
  padding: 2px 15px;
  color: var(--primary);
  margin: 0 3px 4px 0;
}

.main-screen {
  margin: 30px;
}



.contenttt {
  width: 80%;
  float: left;
}

.sidebar-title {
  color: #2196f3;
  /* margin-top: -4rem; */
  padding: 8px 0;
}

.sidebar-menu a {
  text-decoration: none;
  color: inherit;
}

.sidebar-menu {
  margin-top: 150px;
  margin-left: 20px;
  border-radius: 10px 0 0 10px;
  border-top: solid 1px #052d4e;
}

.menu-item {
  padding: 15px;
  border-bottom: 1px solid #052d4e;
  border-left: 1px solid #052d4e;
  border-radius: 10px 0 0 10px;
  color: lightgray;
  font-weight: bold;
  background-color: #00000014;
}
.panelHeight {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sticky{
  position: sticky;
}
.sidebar-menu a {
  position: relative;
}

.menu-item:hover {
  background-color: white;
  /* transition: 0.5s; */
  border-radius: 10px 0 0 10px;
  box-shadow: -1px 2px 2px #1167ab;
  color: #0c46c1;
}

.sidebar-menu>a:hover::before,
.sidebar-menu>a:hover::after {
  content: "";
  position: absolute;
  transition: 0.5s;
}

.sidebar-menu>a:hover::before {
  top: -80px;
  right: -1px;
  height: 80px;
  width: 17px;
  border-bottom-right-radius: 80px;
  -moz-border-bottom-right-radius: 80px;
  -webkit-border-bottom-right-radius: 17px;
  box-shadow: 0 40px 0 0 #ffffff;
}



/* //navbar functionlity */

.sidebar-menu>a:hover::after {
  top: 49px;
  right: -1px;
  height: 80px;
  width: 17px;
  border-top-right-radius: 80px;
  -moz-border-top-right-radius: 80px;
  -webkit-border-top-right-radius: 17px;
  box-shadow: 0 -40px 0 0 #ffffff;
}

.sidebar {
  background: linear-gradient(271deg, #032a49, #0d67af);
  float: left;
  height: 100vh;
  text-align: center;
  top: 0;
  width: 20%;
}


#topbar {
  height: 3.6rem;
  /* margin-top: 1rem; */
  position: sticky;
  z-index: 1;
}

.dropdown-toggle .nav link img {
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
  background: white;
}

.navbar-nav {
  color: white;
}


.navbar-light {
  background-color: #134166 !important;
}

.earning-income-card {
  font-size: 1.5rem;
  padding: 15px 0;
}

.earning-label {
  font-size: 0.9rem;
}

.bg-white {
  background-color: white;
}

.card {
  padding-top: 10px;
  border: var(--bs-card-border-width) solid rgb(0 0 0 / 7%);
}


/* //Dashbord */


.dashboardprovider {
  position: relative;
  left: 2rem;
}

.notification {
  color: #ffff;
  /* box-shadow: 0px 2px 20px 2px #aaaa; */
  margin-left: 20%;
  z-index: 100 !important;

  margin-top: 17px;
  margin-right: 20px;
  height: 100rem;
}

.dropdown-toggle .nav link img {
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
  background: white;
}


.notificationdestop {
  background-color: #fbfbfb;
  box-shadow: 0px 2px 200px 2px #ffff;
  margin-left: 10%;
  margin-top: 201px;
  z-index: 100 !important;
  font-weight: 800;
}









.notificationmobile {
  display: none !important;
}

.schoduledasboard {
  width: 156%;

  position: relative;
  right: 16rem;
}


/* /schudule  */
.schudulecard {
  position: absolute;
  left: 23%;
  width: 76% !important;
  top: 8rem !important;
}

.schedule-card {
  max-width: 90%;
  padding-left: 15rem;
}
.schedule-card2 {
  max-width: 80% !important;
  padding-left: 15rem;
}
.sticky

/* .autoWidth
{
  width: auto !important;
} */

/* //application form */

.formprovider {
  position: relative;
  left: 9%;
}

.progressbarproviders {
  position: relative;
  left: 50px;
}


.document_wraper {
  display: flex;
  width: 100%;
}


.document_wraper .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
  width: 288%;
  /* max-width: 56rem; */
}



.documents-item .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
  width: 288%;
  display: none;
  /* max-width: 56rem; */
}

#isurancecard {
  position: relative;
  left: -33%;
}


.profile_description {
  padding-left: 20px;
}



.modal-backdrop {
  --bs-backdrop-zindex: 1 !important;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}



#upload {
  min-height: 70vh;
  align-content: center;
  display: flex;
  /* margin: 67px; */
  /* padding: 45px; */
  margin-left: 6rem;
  width: 101%;
  /* margin: 16px; */
  /* margin: auto; */
}


.documents {
  display: flex;
}

.documents-body {
  width: 100%;
}

.documents-item {
  width: 100%;
}




#documents-body {
  margin-left: -60%;
}


.noti_icon {
  width: 15%;
  margin-left: 55rem;
  margin-top: 12rem;
  padding-right: 20px;
  z-index: 100;
  background-color: #ffff;
  box-shadow: 2px 0px 200px 0px #aaaa;
}



.noti-item {
  width: 100%;
  background-color: #00e2ff;
  z-index: 100 !important;
}

/* //callstatus */
.callstatus {
  max-width: 60em;
  margin-left: 5rem;
  text-align: center;
  height: 20rem;
  background-color: #ffff;
  box-shadow: 2px 0px 200px 2px #aaaa;
}

.callstatus>h3 {
  padding-top: 8rem;
  font-size: 30px;
  margin: 17px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}



.timerange {
  margin: 10px;
  margin-right: -23rem;
  margin-top: 8px;
}

#secondtimers {
  margin-top: 3.4rem;

}

.thirdstatus {
  background-color: #ffff;
  width: 100%;
  text-align: center;
  margin-left: 37%;
  margin-top: -80px;
}

.statusbody {
  width: 54%;
  box-shadow: 2px 0px 301px 2px #aaaa;
}

#statusinfo {
  position: relative;
  top: -21rem;
  left: 93%;
}

/* new style by Vishal */
.pointer {
  cursor: pointer !important;
}

.tip {
  text-align: center;
  cursor: pointer;
  position: relative;
}

.earn {
  text-align: center;
  font-weight: bold;
  /* border: solid 1px var(--primary); */
  position: relative;
  /* border-radius: 50%; */
  font-size: 20px;
  color: black;
  /* box-shadow: 0px 0px 10px #2196f375; */
}

.earn span {
  color: var(--primary);
}

.tip p {
  margin: 3px;
  font-size: 12px;
  color: var(--primary);
}

.cardLeft {
  color: grey;
  font-size: 13px;
}

.card-body {
  cursor: pointer !important;
  padding: 2rem;
}

.modalBody {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: #000000bf;
  top: 0;
  left: 0;
}

.modalCard {
  min-height: 50vh;
  background: white;
  margin-top: 10vh;
}

.modalClose {
  color: white;
  width: 40px;
  height: 40px;
  background: red;
  padding: 9px 0;
  text-align: center;
  border-radius: 50%;
  position: relative;
  left: 98%;
  bottom: 10px;
  z-index: 1000;
}

.modal {
  background: #000000cf;
  top: 0;
  left: 0;
}

.title {
  font-weight: bold;
}

.detailsTable {
  font-size: 14px;
}

.detailInfo {
  padding: 5px 25px;
}

.detailTitle {
  padding: 5px 10px;
  background: #edebeb;
  border-radius: 10px;
}
.profileBtn .btn{
  margin: 0 5px;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: none !important;
}

#login_pages{
  width: 100%;
  background: url(../Components//assets/img/9496304.png);
	background-size: 100% 100%;
	background-attachment: fixed;
  width: 100%;
	height: 100vh !important;
	overflow-y: scroll;

}


@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .schoduledasboard .schedule-card{
    padding-left: 15rem !important;
  }
  .schedule-card {
    /* max-width: 90%; */
    padding-left: 0rem !important; 
  }
  .notificationmobile {
    margin-top: 1px;
    margin-left: 50px;
    display: block !important;
  }

  .contenttt {
    width: 100% !important;
  }

  .main-screen {
    margin: 0;
  }

  .notificationdroupdown {
    background-color: #ffff;
    box-shadow: 0px 2px 200px 2px #aaaa;
    margin-left: 60%;
    z-index: 100 !important;
  }
  .row{
    margin: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-nav {
    position: absolute;
    right: 145px;
  }
  .earning-income-card {
    font-size: 1.5rem;
    padding: 5px 0;
  }
  .navbar-brandn {
    visibility: hidden;
  }

  #links {
    display: none;
  }

  .schudulecard {
    position: absolute;
    left: 22%;
    width: 100%;
    top: 2rem;
  }

}


@media only screen and (min-width: 768px) {
  .navbar-nav {
    position: absolute;
    right: 145px;
  }

  .navbar-brandn {
    visibility: hidden;
  }

  #links {
    display: none;
  }


}

@media (max-width:992px) {
  .schoduledasboard {
    width: 176%;
    position: relative;
    margin: auto;
    /* right: 14rem; */
}
  .schudulecard {
    position: absolute;
    left: 0% !important;
    width: 100% !important;
    top: 10rem !important;
  }


  .formprovider {
    position: relative;
    left: 0;
  }

  .documents {
    display: block !important;
  }

  #isurancecard {
    position: relative;
    left: -1%;
  }

  #documents-body {
    margin-left: 1px;
  }

  #upload {
    min-height: 70vh;
    align-content: center;
    display: flex;
    /* margin: 67px; */
    /* padding: 45px; */
    margin-left: 0rem;
    width: 101%;
    /* margin: 16px; */
    /* margin: auto; */
  }
  #login_pages .sign_in_form
  {
    margin-top: 20px;
  }
  #topbar img {
    margin-top: 10px !important;
}
.earning-income-card {
  font-size: 1.5rem;
  padding: 5px 0 !important;
}
/* .col-sm-6{
  width: 50% !important;
} */
}

@media (max-width:475px) {
.modal-content {
  zoom: 0.7;
}
}