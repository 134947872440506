

.header_dashbord{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}




.button.primary {
    padding: 12px 25px 13px 25px;
    border-radius: 25px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 25%);
    font-size: 11px;
    letter-spacing: 2px;
    word-spacing: 3px;
    background: linear-gradient(45deg, #2196f3, #00bcd4);
    border: none;
    color: #fff;
}

.button.small {
    font-size: 10px;
    padding: 1.6em 2.5em;
}

.card.padded {
    padding: 15px;
    overflow: visible;
}

.card.padded .input_group {
    margin: 30px 0px;
}

#login {
    position: relative;
    display: block;
    width: 100%;
}

#login .background {
    position: relative;
    display: block;
    width: 100%;
    height: 350px;
    /* background-image: url(../img/curved11-small.jpg); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

#login .background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0a3c62 0%, #2196f3 100%);
}

#login .background .head {
    text-align: center;
    z-index: 1;
    position: relative;
    display: block;
    margin-top: 175px;
}

#login .background .head h3 {
    color: #fff;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
}

#login .background .head p {
    text-align: center;
    display: inline;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.5px;
}

#login #login_form {
    margin-top: -100px;
}

#login .heading {
    padding-left: 0;
}

/* 
#footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.15) -50%, #f9f9f9 100%);
}

#footer span.title {
    float: right;
    padding-right: 25px;
    font-size: 11px;
    letter-spacing: 1px;
    word-spacing: 3px;
    font-weight: 500;
    text-transform: uppercase;
    color: #444444;
}

#footer span.title a {
    color: #8e8e8e;
    text-transform: uppercase;
    font-weight: 400;
} */
/* 
#sidebar {
    position: fixed;
    top: 0;
    left: -200px;
    bottom: 0;
    width: 200px;
    z-index: 10;
    transition: all 0.15s ease-in;
}

#sidebar.active {
    left: 0;
}

#sidebar .card {
    overflow: auto;
    height: 100vh;
    padding: 10px;
    padding-bottom: 50px;
    background: #0a2840;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 10px 0px 10px 0 rgb(0 0 0 / 25%);
    border: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

#sidebar hr {
    margin: 0px 0px;
    opacity: 0.1;
}

#sidebar .item {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 12px;
}

#sidebar .nav .item .title {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    color: #ffffff;
    cursor: pointer;
    padding-left: 45px;
    padding-top: 16px;
    padding-bottom: 16px;
}

#sidebar .nav ul,
#sidebar .nav ul li {
    margin: 0;
    padding-left: 20px;
}

#sidebar .nav ul li a {
    text-decoration: none;
    padding: 5px;
    width: 100%;
    display: block;
}

#sidebar .nav span.icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 15px;
    opacity: 1;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
    filter: invert(1);
}

#sidebar .nav ul li a span.text {
    font-size: 13px;
    font-weight: 400;
    color: #6d6d6d;
    text-transform: capitalize;
    word-spacing: 1px;
}

#sidebar .infobox_big .item,
#sidebar .infobox_big .item .title,
#sidebar .infobox_big .item .excerpt {
    text-align: left;
    color: #ffffff;
    font-weight: 700;
    transform: scale(1.05);
    transform-origin: left;
    overflow: hidden;
}

#sidebar .infobox_big.avatar {
    padding-left: 12px;
} */
/* #avtar {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 52px;
    margin-bottom: 10px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

} */



/* 

#sidebar .icon.cross {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    display: block;
    z-index: 1;
    cursor: pointer;
} */
/* 
#sidebar .nav ul {
    margin-bottom: 12px;
}

#sidebar .drpdwn .status_ico {
    top: 17px;
}

#sidebar .nav {
    margin-top: 45px;
} */

#content {
    position: relative;
    display: block;
    padding: 0px 10px;
    left: 200px;
    width: calc(100% - 200px);
}

#content .gutter {
    padding: 0px 10px;
}

.headings {
    position: relative;
    display: block;
    width: 100%;
    margin: 25px 0px;
    padding-left: 10px;
}

.headings h3 {
    text-transform: uppercase;
    font-size: 15px;
    margin: 0;
    color: #5e5e5e;
    font-weight: 700;
    letter-spacing: 0.5px;
    word-spacing: 2px;
    margin-bottom: 4px;
}

.headings p {
    text-transform: capitalize;
    font-size: 12px;
    color: #717171;
    margin: 0;
}

.dash_card {
    padding: 20px 10px;
    min-height: 100px;
    padding-top: 25px;
}

.dash_card .content {
    position: relative;
    display: block;
    padding-right: 90px;
    padding-left: 20px;
}

.dash_card .icon {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 64px;
    height: 64px;
    background: #f9f9f9;
    border-radius: 50%;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}

.dash_card .content .head {
    position: relative;
    display: block;
    font-size: 13px;
    text-transform: capitalize;
    color: #666;
}

.dash_card .content .value {
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #3c3e49;
}

.dash_card .content .extra_info {
    position: relative;
    display: block;
    font-size: 15px;
    color: #dbaa2f;
}

.table {
    margin: 0;
    border: 1px solid #f1f1f1;
}

tr:nth-child(even) {
    background: rgb(249 249 249 / 0.6);
}

tr:nth-child(odd) {
    background: #FFF
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: 1px solid rgb(0 0 0 / 11%);
    padding-top: 15px;
    padding-bottom: 15px;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-bottom: 0;
}

.summary_collections {
    position: relative;
    display: block;
    width: auto;
    margin-left: -10px;
    margin-right: -10px;
}

.summary_collections .card {
    padding: 10px;
    text-align: center;
    background: #fff;
    border: 1px solid #00000029;
}

.summary_collections .card .icon {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
}

.summary_collections .card h3 {
    margin-top: 10px;
    font-weight: 900;
}

.filters .input_group {
    max-width: 200px;
    margin: 0;
    display: inline-block;
}

.bookings {
    position: relative;
    display: block;
    width: 100%;
}

.bookings .item_wrapper {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.bookings .item_wrapper .item {
    padding: 20px;
}

.bookings .item_wrapper .item .first_half {
    position: relative;
    display: block;
    width: 50%;
    float: left;
}

.bookings .item_wrapper .item .first_half h3 {
    margin: 0;
    text-transform: capitalize;
    font-size: 17px;
    margin-bottom: 10px;
    color: #3e3e3e;
    font-weight: 400;
}

.bookings .item_wrapper .item .first_half .address {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 30px;
    text-transform: capitalize;
    color: #666;
    margin-bottom: 5px;
    font-size: 16px;
}

.bookings .item_wrapper .item .first_half .address::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 23px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    /* background-image: url('../img/clock (2).png'); */
    opacity: 0.7;
}

.bookings .item_wrapper .item .first_half .time {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 16px;
}

.bookings .item_wrapper .item .first_half .time::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 23px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85%;
    /* background-image: url('../img/maps-and-flags.png'); */
    opacity: 0.7;
}

.bookings .item_wrapper .item .first_half .tag {
    position: relative;
    display: block;
    width: 100%;
    font-size: 13px;
    color: #525252;
    font-weight: 400;
}

.bookings .item_wrapper .item .second_half {
    position: relative;
    display: block;
    width: 50%;
    float: right;
    text-align: right;
    padding-top: 20px;
    color: #666;
}

.bookings .item_wrapper .item .second_half span {
    position: relative;
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
}

.bookings .item_wrapper .item .second_half span.colored {
    color: #2196f3;
    font-weight: 600;
}

.button.square {
    border-radius: 8px;
}

.chats_holder {
    position: relative;
    display: block;
    width: 100%;
    background-color: #e3e3e3;
    min-height: 100vh;
    padding: 10px;
}

.chats_holder .item_wrapper {
    position: relative;
    display: block;
    width: 100%;
    margin: 6px 0px;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
}

.chats_holder .item_wrapper .item {
    position: relative;
    display: block;
    width: 100%;
}

.chats_holder .item_wrapper .item .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.chats_holder .item_wrapper .item[active] .bg::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background: linear-gradient(135deg, #7ce8ff, #00acdf);
    border-radius: 50%;
    border: 2px solid #fff;
}

.chats_holder .item_wrapper .item .text {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 50px;
    padding-top: 4px;
}

.chats_holder .item_wrapper .item .text .name {
    position: relative;
    display: block;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.chats_holder .item_wrapper .item .text .time {
    position: relative;
    display: block;
    font-size: 10px;
    text-transform: capitalize;
}

.chats_holder .item_wrapper .item .text .message {
    font-size: 11px;
    color: #999;
    position: relative;
    display: block;
}

#modal_chat .scrollable {
    position: relative;
    padding-top: 70px;
    padding-bottom: 80px;
    min-height: 100vh;
    background: #f9f9f9;
}

#modal_chat .message_holder .item_wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px;
}

#modal_chat .message_holder .item_wrapper .item {
    position: relative;
    display: block;
    width: 70%;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 14px;
    padding: 12px;
    font-size: 12px;
    color: #666;
    box-shadow: 0px 3px 3px -5px rgb(0 0 0 / 25%);
}

#modal_chat .message_holder .item_wrapper .item.message {
    background: linear-gradient(318deg, #7be8ff, #00acdf);
    color: #fff;
}

#modal_chat .message_holder .item_wrapper .item.reply {
    background: linear-gradient(318deg, #ffffff, #ebebeb);
    left: 30%;
}

#modal_chat .message_holder .item_wrapper .item .time {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
    font-size: 11px;
    color: #8d8d8d;
}

#modal_chat .message_holder .item_wrapper .item.message .time {
    color: #fff;
}

#modal_chat .writing_pad {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

#modal_chat .writing_pad .input {
    width: 100%;
    border: 0;
    padding: 10px;
    display: block;
}

#modal_chat .writing_pad .input:focus {
    outline: 0;
}

#modal_chat .writing_pad .send_icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    bottom: 0;
    background: #fff;
}

#modal_chat .writing_pad .send_icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 40%;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-image: url('../img/share.svg'); */
    opacity: 0.65;
}

#content.fixed {
    position: fixed;
    top: 0;
    left: 200px;
    width: calc(100% - 200px);
    height: 100%;
}

.top_bar.fixed {
    height: 55px;
    box-shadow: 0px 1px 6px -3px rgb(0 0 0 / 45%);
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background: #fff;
}

.top_bar.fixed .back_button {
    left: 15px;
    top: 12px;
}

.top_bar .title {
    position: absolute;
    left: 15px;
    top: 19px;
    font-size: 13px;
    font-weight: 500;
}

tbody .bg {
    position: relative;
    display: block;
    width: 64px;
    height: 64px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
}

.table .content {
    padding: 0px 7px;
    display: block;
    float: left;
    max-width: calc(100% - 74px);
    left: 10px;
    position: relative;
}

.table .content.no_pad {
    float: none;
    left: 0;
    max-width: initial;
}

.table .content .title {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table tr .content .controls {
    position: relative;
    display: block;
    opacity: 0;
    transition: all 0.1s ease-in;
    margin-top: 10px;
}

.table tr:hover .content .controls {
    opacity: 1;
}

.card_label {
    position: relative;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: #6d6d6d;
    padding-left: 10px;
    padding-top: 10px;
    margin: 0;
    padding-right: 40px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}

.ck.ck-editor__main>.ck-editor__editable {
    min-height: 300px;
}

.image_placeholder {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100%;
}

.image_placeholder::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    max-width: 32px;
    max-height: 32px;
    /* background: url('../img/image.svg'); */
    opacity: 0.35;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 9;
    transform: translate(-50%, -50%);
}

.image_placeholder[loaded]::before {
    display: none;
}

.attachment_wrapper {
    position: relative;
    display: block;
    width: calc(33.333% - 4px);
    float: left;
    margin: 2px;
    border: 1px solid #c5c5c5;
    max-width: 120px;
    /* 16:9 */
    max-height: 120px;
}

.attachment {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.attachment_wrapper .removeBtn {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 24px;
    height: 24px;
    background: #fff;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
}

.attachment_wrapper:hover .removeBtn {
    opacity: 1;
}

.attachment_wrapper .removeBtn::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    /* background: url(../img/icons/add.svg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(50%, 50%) rotate(45deg);
}

#about_user_card {
    position: relative;
    display: block;
    width: 100%;
    padding: 25px;
}

#about_user_card .avatar_wrap {
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
}

#about_user_card .avatar {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100px;
    height: 100px;
    background: #000;
    border-radius: 50%;
    border: 3px solid #03a9f4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 5px 10px #2196f394;
}

#about_user_card .avatar_wrap .name {
    position: relative;
    display: inline-block;
    top: -13px;
    left: -17px;
    font-size: 19px;
    font-weight: 400;
    color: #666;
    width: auto;
}

#about_user_card .time {
    display: block;
    margin-bottom: 10px;
}

#about_user_card .education {
    margin-top: 30px;
    position: relative;
}

#about_user_card .education::after {
    content: '';
    position: absolute;
    top: 23px;
    left: -38px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #8dc7f5;
    background: #fff;
    z-index: 1;
}

#about_user_card .education_wrap {
    position: relative;
    display: block;
    padding-left: 30px;
}

#about_user_card .education_wrap::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    background: #8dc7f5;
    height: 100%;
}

#about_user_card .avatar_wrap .status {
    position: relative;
    color: #666;
}

#about_user_card .avatar_wrap .status::after {
    content: '';
    position: absolute;
    top: 4.5px;
    right: -19px;
    width: 15px;
    height: 15px;
    background: #8bc34a;
    border-radius: 50%;
    border: 2px solid #7caf40;
}

.inner_title {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 300;
    margin-bottom: 25px;
}

#header {
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
    background-color: #000;
}

.toggle_sidebar {
    display: none;
}

#content .inner {
    justify-content: center;
    text-align: start;
}

.card.layer2 {
    border-radius: 12px;
    box-shadow: 0 10px 20px -15px rgba(0, 0, 0, .5);
    font-size: 12px;
    margin-top: 12px;
}
.backarrow{
    color: darkgrey !important;
    font-size: 1.5em;
}
#post_container .cursor{
    color:rgb(26, 26, 26);
}
.smallDetails {
    text-align: center;
    width: 100%;
}

.smallDetails td, .smallDetails th {
    border: solid 1px grey;
    text-align: center;
    padding: 5px 5px;
}
.vspace50{
    height: 50px;
}
.link{
    color: #0a3c62 !important;
    cursor: pointer;
}
.link:hover{
    color: #03a9f4 !important;
    transition: .3s;
}
.searchResult {
    position: absolute !important;
    z-index: 999;
    right: 37px;
    background: #f5f5f5a6;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.searchResult .card {
    padding: 10px !important;
}
.searchResult p{
    margin: 0 !important;
}
