

/*extra small*/
@media (max-width:992px) {
    .modal-wrapper-review {
        width: 100% !important;
        margin: auto;
        /* background-image: url("../img/productive_image123.jpg"); */

    }

    .modal-content-review-user {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 100% !important;
        max-width: 90% !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        margin-top: 150px;
    }

    .notificationdestop {
        display: none;
    }

    .notification {
        display: none !important;
    }

    .notificationdroupdown {
        background-color: #ffff;
        box-shadow: 0px 2px 200px 2px #aaaa;
        margin-left: 60%;
        z-index: 100 !important;
    }

    #navigation .nav_bar {
        position: fixed;
        top: 60px;
        bottom: 0;
        background-color: #162b3c;
        right: 0;
        width: 80%;
        padding-top: 15px;
        transform: translateX(100%);
        display: none !important;
    }

    #navigation[active] .nav_bar {
        display: block !important;
        animation-name: slideNavLeft;
        animation-duration: 0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        transform: translateX(0%);
    }

    @keyframes slideNavLeft {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0%);
        }
    }

    #navigation {
        height: 60px;
        left: 0;
        top: 0;
        right: 0;
    }

    #navigation .logo {
        transform: scale(0.9);
        transform-origin: left;
        left: 5px;
    }

    #navigation .nav_bar li {
        display: block;
        float: none;
        margin: 0;
        padding-left: 25px;
    }

    #navigation .drpdwn:hover .drpdwn_menu {
        display: none;
    }

    #navigation .drpdwn[active] .drpdwn_menu {
        width: calc(100% + 25px);
        position: relative;
        left: -25px;
        border-radius: 0;
        background: #0f1d28;
        padding: 12px 25px;
        display: block;
    }

    #navigation .drpdwn .drpdwn_menu li:first-child {
        margin-top: 0;
    }

    #navigation .drpdwn .drpdwn_menu li a {
        color: #fff;
        text-shadow: none;
    }

    #navigation .nav_bar li.withicon {
        margin-left: 0;
    }

    #navigation .nav_bar li.withicon {
        display: inline-block;
    }

    #navigation .nav_bar li.withicon:last-child {
        padding-left: 0;
    }

    #navigation .nav_bar li .icon {
        display: none;
    }

    #navigation .nav_bar li.withicon a {
        top: 0;
        margin-bottom: 12px;
        padding: 11px 15px;
        width: auto;
        display: inline-block;
        margin-top: 10px;
    }

    #banner .head h1 {
        margin-top: 2rem;
        font-size: 25px !important;
    }

    #banner .head h3 {
        font-size: 12px;
    }

    #banner .arrow_down {
        bottom: 15%;
        left: 48%;
    }

    #navigation .toggle_button {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 32px;
        height: 32px;
        /* background-image: url('../img/menu.png'); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        filter: invert(1);
    }

    #navigation[active] .toggle_button {
        /* background-image: url('../img/close.png'); */
        background-size: 50%;
        opacity: 9;
    }

    #classes {
        margin-top: 20px;
    }

    #banner .head {
        text-align: center;
        width: 100%;
        padding: 15px;
    }

    #banner .head .button {
        margin: 1px 0px;
        padding: 8px 22px;
        margin-left: 1rem;
        width: 145px;
    }

    #downloadimage {
        width: 160px;
        padding: 8px;
    }

    .button.primary {
        padding: 10px 15px !important;
        border-radius: 25px;
        box-shadow: 0px 5px 10px rgb(0 0 0 / 25%);
        font-size: 11px;
        letter-spacing: 2px;
        word-spacing: 3px;
        background: linear-gradient(45deg, #2196f3, #00bcd4);
        border: none;

    }

    #types {
        position: relative;
        padding: 12px;
    }

    #types .item_wrapper {
        padding: 15px 5px;
    }

    #types .content p {
        font-size: 11px;
    }

    #types .content h3 {
        font-size: 14px;
    }



    #detailblogitem>h3 {
        width: 100% !important;
        position: relative;
        text-align: center;
        font-size: 25px !important;
        top: -40px;
        left: 0;
    }



    #types #detailblogitem>p p {
        position: relative;
        text-align: center;
        right: 0;
        top: -40px;
        width: 100% !important;
        font-weight: 700 !important;
        font-size: 14px !important;
    }



    #detailimage {
        width: 100% !important;
        height: 20rem !important;
        position: relative;
        top: -5rem;
        right: 0;
    }










    #brief .content {
        text-align: center;
    }

    #about {
        margin-top: 20px;
        padding: 10px;
    }

    #about .about_content {
        padding: 10px;
    }

    #about .about_content .button {
        margin: 0 !important;
        float: left;
        width: auto;
        padding: 7px 15px;
        margin-left: 5px !important;
    }

    #about .about_content p {
        font-size: 12px;
    }

    #about .item::after {
        display: none;
    }

    #about .item .content {
        padding: 20px;
    }

    #about .item .content p {
        margin: 0;
        font-size: 12px;
    }

    /* .img-responsive{
    position: relative;
    width: 100%;
    left: -1.5rem;
    width: 100%;

    } */


    img.img-responsive {
        border-radius: 12px;
        height: 40vh;
        padding-left: 0;
        width: 100%;
    }

    #about .left_half .img-responsive {
        /* width: 300px; */
        max-width: 100%;
        margin: auto;
    }


    #faq_page {
        position: relative;
        display: block;
        margin: 27px 0px;
        width: 95%;
        margin-left: .7rem;
    }




    #brief {
        margin-top: 12px;
        padding-bottom: 0px;
        padding-top: 30px;
    }

    #brief .infobox .text {
        text-align: left;
    }

    #brief .infobox .icon {
        top: 15px;
    }

    #brief .content p {
        font-size: 13px;
        color: #232018;
        width: 100%;
        /* margin-left: -2.5rem; */
    }

    #brief .content.heading h3 {
        /* position: relative;
        right: 3rem; */
        font-size: 20px !important;
        text-transform: capitalize;
        font-weight: 800;
        color: #29251a;
        width: 100%;
    }

    #brief .content.heading h5 {
        /* position: relative;
        right: 3rem; */
        font-size: 15px !important;
        font-weight: 300;
        margin-bottom: 32px;
        color: #29251a;
    }

    #AdvantageBrief {
        justify-content: center;
        text-align: center;
        /* margin-left: 20px; */
    }


    #AdvantageBrief h3 {
        justify-content: center;
        text-align: center;
        margin-left: 60px;
    }

    #AdvantageBrief .textbrief {
        justify-content: center;
        text-align: center;
        margin-left: 80px;
        position: relative;
        left: 40px;
    }



    #download_app {
        padding: 50px 15px;
    }

    .heading {
        position: relative;
        left: 0;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .headinghome {
        position: relative;
        top: 1rem !important;
        height: 20rem;
        /* left: -2.5rem; */
        width: 100%;
        text-align: center;
        justify-content: center;

    }

    #headingprovider {
        position: relative;
        /* left: -2.5rem; */
        width: 100%;
        text-align: center;
        justify-content: center;

    }

    #download_app .heading a img {
        width: 50%;
        margin-top: 8px;
    }

    #download_app .gutter {
        padding: 0 !important;
        margin-top: 25px;
    }

    #download_app .heading {
        margin-top: 30px;
        text-align: center;
        padding: 0px 10px;
        width: 113%;
        padding-left: 63px;
    }


    #provider .heading {
        margin-top: 30px;
        text-align: center;
        padding: 0px 10px;
        width: 100%;
    }

    #provider .right_half {
        height: 300px;
        width: 90%;
        left: 4%;
        top: 25px;
        border-radius: 10px;
    }

    .right_half #right_half_image {
        height: 15rem;
        width: 100%;
    }

    #messagebannerdown {
        text-align: center;
        justify-content: space-around;
        margin-top: 6.5rem !important;
        margin-left: 0.2rem !important;

    }

    #footer {
        padding: 15px;
    }

    #footer_strip .copyright h3 {
        text-align: center;
    }

    .copyright {
        float: none !important;
    }

    #small_banner .head {
        position: relative;
        padding: 0px 15px;
        text-align: center;
        height: 90vh;
        width: 100%;
        padding-bottom: 60px;
        background: #ffffffa3;
        -webkit-backdrop-filter: blur(0px) !important;
        backdrop-filter: blur(0px) !important;
    }

    #small_banner .head h1 {
        padding-top: 50px;
    }


    #small_banners .heads {
        position: relative;
        top: 34%;
        left: 0%;
        padding: 0px 15px;
        text-align: center;
        height: auto;
        width: 100%;
        padding-bottom: 60px;
    }

    #alternate_post .heading {
        padding: 3px;
        justify-content: center;
        text-align: center;
        width: 100%;
        /* padding-left: 6rem; */
        margin-top: 20px;
    }


    #alternate_post .heading p {
        padding: 3px;
        justify-content: center;
        text-align: center;
        width: 100% !important;
    }





    #faqtext {
        position: relative;
        justify-content: center;
        text-align: center;
        padding-left: 80px;
    }



    #member_cta {
        padding: 0px 14px;
    }

    #profile_wizard_carousel {
        display: none;
    }

    #section_group {
        padding: 12px;
    }

    #progressPills {
        left: 10px;
        width: calc(100% - 20px);
        margin-bottom: 13px;
    }

    .gender_holder li {
        width: calc(133% - 10px) !important;
        /* float: left; */
        margin-left: -17px;
    }

    /* #contact_us .heading {
        margin-top: -150px;
        padding-left: 4rem;
    }

    #contact_us .heading h3 {
        font-size: 38px;
    } */

    /* #contact_us .heading p {
        font-size: 13px;
        margin-top: -150px;
        padding-left: 4rem;
    } */

    #membership_benifits .content ul {
        position: relative;
        display: block;
        z-index: 1;
        list-style: none;
        padding: 25px;
        text-align: left;
        margin-top: 34px;
    }

    #membership_benifits .content ul li {
        width: 100%;
        float: none;
        margin: 0;
        min-height: 105px;
    }

    #services_tabs ul li {
        width: 47%;
        float: none;
        display: inline-block;
    }

    #services_tabs ul {
        text-align: center;
    }

    .member_form_wrap {
        padding-right: 10px;
        padding-left: 10px;
    }

    .selectable li {
        overflow: hidden;
    }

    .sidebar.tabs {
        display: none;
    }

    #profile_page .content {
        padding: 0 5px;
        margin-bottom: 35px;
    }

    .sidebar {
        padding: 12px;
        transform: translateY(-100%);
        position: absolute;
        left: 0;
        right: 0;
        height: auto;
        z-index: 9;
        box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 50%);
    }

    .sidebar[active] {
        transform: translate(0%);
    }

    .sidebar ul li {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .appoint_wrapper,
    .appoint_wrapper .notify {
        padding: 0;
        min-height: 100px;
    }

    .appoint_wrapper .notify .bg {
        width: 80px;
        height: 80px;
    }

    #profile_page .appoint_wrapper .notify .content {
        padding-left: calc(80px + 15px) !important;
    }

    .halve.pull-right .button {
        padding: 3px 6px;
    }

    #profile_page {
        /* margin-top: 62px; */
        margin-bottom: 50px !important;
    }

    #brief .video_wrapper {
        position: relative;
        right: 30%;
        width: 110%;
    }


    #brief .content .button {
        position: relative;
        right: 20%;
    }

    #videofile {
        left: 2.2rem;
        display: flex;

        width: 80%;
    }




    #videofiletext {
        position: relative;
        margin-left: -2rem;

    }

    .textmonian {
        width: 100% !important;
        margin: auto;
    }

    #talent .wrapper .item {
        top: 0 !important;
    }

    #talent .buttons_pane {
        text-align: center;
        margin: 0;
        margin-left: 0 !important;
    }

    #talent .wrapper .item::before,
    #talent .wrapper .item .tri {
        display: none;
    }

    #talent .big_head {
        padding: 0 30px;
    }

    #talent .big_wrapper {
        margin-top: 0px;
        padding-bottom: 36px;
    }

    #talent {
        padding-top: 35px;
    }


    #small_banner {
        height: auto;
    }

    #alternate_post .bg {
        height: 250px;
        left: 15px;
        width: calc(100% - 30px);
    }

    #alternate_post {
        margin-top: 20px;
    }

    .service_controls .prev {
        left: 5px;
    }

    .service_controls .next {
        right: 5px;
    }

    #classes .item_wrapper {
        padding: 15px 5px;
    }

    #small_banner .button {
        margin-top: 70px;
    }

    .member_form_wrap_bg {
        height: 250px;
        background-position: top;
    }

    #navigation .nav_bar .app_buttons {
        display: block;
        left: 15px;
        position: relative;
        margin-top: 10px;
        width: auto;
    }

    #navigation .nav_bar .app_buttons a:first-child img {
        width: 44%;
        margin-right: 2%;
    }


    #navigation .nav_bar .app_buttons a img {
        width: 40%;
        float: left;
    }

    #over_banner .sign_in_form {
        margin-top: -30px;
    }

    #navigation .drpdwn .ico {
        position: absolute;
        top: 0px;
        right: -40px;
        width: 40px;
        height: 40px;
        display: block;
    }

    #navigation .drpdwn .ico::after {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        left: calc(50% - 6px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #fff transparent transparent transparent;
    }


    #navigation .nav_bar li .text,
    #navigation .nav_bar li .text a {
        position: relative;
        display: inline-block;
    }

    .toggleimage {
        display: block;
    }

    #memershipbutton {
        position: relative;
        top: -2rem !important;
        left: 30% !important;

    }

    .sign_in_form {
        position: relative;
        justify-content: center;
        width: 90% !important;
        left: -39.6% !important;
        margin-top: -50px;
    }

    .sign_up {
        margin-top: -20px !important;
    }


    #over_banner .location {
        position: relative;
        top: 50vh;
        max-width: 600px;
        z-index: 9;
        display: inline-block;
        transform: translateY(-50%);
        width: 100%;
        border-radius: 12px;
        box-shadow: 4px 6px 10px -3px rgb(0 0 0 / 30%);
        border: 1px solid #e3e3e3;
        padding: 30px 30px;
        background: #fff;
        left: 0 !important;
    }


    .dashboardprovider {
        position: relative;
        width: 100%;
        left: 0 !important;
    }

    nav .container {
        z-index: 2;
        background: white;
        padding-bottom: 10px;
        box-shadow: 0px 2px 5px #f2f2f2;
    }

    .formprovider {
        position: relative;
        left: 0%;
        padding: 0 15px;
    }

    .progressbarproviders {
        position: relative;
        left: 0 !important;

    }

    #video_section {
        margin-left: 0px;
    }

    .blogPage {
        padding: 50px 0px;
    }

    #detailimage {
        background-repeat: no-repeat;
        background-size: cover;
    }

    .centerFlex {
        margin-top: -75px;
    }

    #employees {
        padding-top: 0 !important;
        margin-top: -40px;
    }

    .whoIs {
        margin-top: 100px;
    }

    .booking_tabs ul {
        display: flex;
        width: 100%;
        /* padding: 0; */
        margin: 0;
        box-sizing: border-box;
        /* padding: 0 10px !important; */
    }

    .booking_tabs ul li {
        margin: 0 !important;
        padding: 3px !important;
        font-size: 11px;
    }

    .selectable {
        font-size: 10px;
    }

    .checkkout {
        z-index: 0 !important;
        display: block !important;
        margin-top: 20px !important;
    }

    html {
        height: -webkit-fill-available;
    }


}




/* .sign_in_form {
    position: relative;

    width: 100%;
    height: 70%;
    left: -11rem;
} */



.sign_in_forms {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin-top: 50px;
    background: #fff;
    margin-top: 100px;
    margin-left: 1px;
    padding: 50px 25px;
    border-radius: 8px;
    box-shadow: 4px 10px 20px 1px #00000059;
    margin-bottom: 20px;
}


/* //about page */


















/*extra small end*/
/*small*/
/* @media (min-width:769px) { */
@media (max-width:769px) {
    #services_tab .container-brief {
        justify-content: space-around;
        text-align: center;
        display: block;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px;
    }

    #services_tab .container-brief .selects .item {
        /* display: block; */
        flex-basis: 145%;
        margin: auto;
        width: 40%;


    }


    #gmaillogin {
        justify-content: center;
        text-align: center;

    }

    .sign_in_forms {
        margin-top: 0;
    }

    .sign_in_forms .sign_in_icon {
        height: 150px;
    }


}

/*small end*/
/*medium */
@media (min-width:992px) {
    .pull-big-right {
        float: right;
    }
}

/*medium end*/
/*large*/
@media (min-width:1200px) {}

/*large end*/

@media  (max-width: 1200px) {

    #navigation .logo img {
        width: 172px !important;
        margin-top: -5px;
    }

    #navigation .nav_bar li a {
        position: relative;
        display: block;
        font-size: 13px;
        font-weight: 300;
        text-transform: capitalize;
        padding: 11px 0px;
        color: #ffffff;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
    }

}