/* Styles for the tab menu */
:root {
	--theme: #03a9f4;
}

.progressbar_userpannel {
	width: 100%;
	/* max-height: 100vh !important;
    overflow-y: scroll; */
}

/* .profileSpace {

	background: url(../../../assets/img/9496304.png);
	background-size: 100% 100%;
	background-attachment: fixed;
	width: 80%;
	height: 100%;
	margin: auto;
	position: absolute;
	top: 100px;
	right: 0;
	left: 20%
} */
.profileSpace {

	background: url(../../../assets/img/9496304.png);
	background-size: 100% 100%;
	background-attachment: fixed;
	width: 80%;
	height: 90vh !important;
	overflow-y: scroll;
	position: absolute;
	right: 0;
	bottom: 0;
	top: 10;
}

.parent {
	display: flex;
	/* margin-top: 60px; */
}

.pagination li {
	background: none !important;
}

.memberships {
	display: flex;
}

.sidebar_tab {
	/* position: fixed;
	display: block; */
	width: 20%;
	padding: 0px 0px !important;
	top: 60px;
	background: linear-gradient(91deg, #032a49, #0d67af);
	text-align: center;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: sticky;
	height: 100vh;
	top: 0;
}

.sidebar_tab ul#tabs_control {
	list-style: none;
	padding: 20px;
	margin: 0;
	/* width: 60%; */
	margin: auto;
	/* display: flex;
	flex-direction: row;
	flex-wrap: nowrap; */
	cursor: pointer;
	color: #fff;
	/* justify-content: center; */
	/* justify-content: space-evenly; */
	/* text-align: center; */
}

ul#tabs_control li {
	/* display: flex;
	flex-direction: row;
	justify-content: space-around;
	/* display: inline-block; */
	cursor: pointer;
	color: #fff;
	font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	/* border: 2px solid seagreen; */
	margin: 10px 0;
	padding: 10px;
}

/* Text color of inactive tabs */
ul#tabs_control .item {
	display: flex;
	/* justify-content: space-around; */
	align-items: center;
}

ul#tabs_control .item img {
	margin-right: 5px;
	margin-top: 5px;
	/* background-color: #03a9f4; */
	color: #004f8f;
}

ul#tabs_control li.active {
	background: linear-gradient(91deg, #011423, #07518d);
	border-radius: 6px;
	color: #fff;
	/* border: 2px solid #88daff; */
	/* width: 10%; */
	/* height: 30px; */
	/* justify-content: center; */
	/* text-align: center; */
}

/* ul#tabs_control li:hover {
    color: #03a9f4;

} */

/* //Overview */

#user_profile_page {
	width: 80%;
	margin: auto;
	position: absolute;
	top: 100px;
	right: 0;
	left: 15%
}

#overview_page_container {
	/* width: 80%; */
	margin: auto;
	display: flex;
	justify-content: center;
}

.inner {
	justify-content: center;
	text-align: center;
}

.inner .small_heading {
	font-size: 16px;
}

.button_direction {
	width: 100%;
	white-space: nowrap;
	font-size: 12px;
	box-shadow: none;
	background: #fff;
	display: inline-block;
	padding: 10px 14px;
	color: #848484;
	text-transform: uppercase;
	border: 1px solid #dedede;
	border-radius: 20px;
}

#button_invoices .pull-right {
	font-size: 10px;
	background-color: red;
}

/* //Boking */
#booking_twext_item {
	position: relative;
	top: -15px;
}

.overview {
	/* background-color: #ffffff6e; */
	padding: 10px 0;
	width: 100%;
	margin: auto;
}

.overview .overview_container {
	width: 100%;
	margin: auto;
	justify-content: center;
	text-align: center;
	margin-top: 20px;
}

.overview__containerMain {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.overview .overview_container .heading h3 {
	font-size: 21px;
	text-transform: capitalize;
	font-weight: 300;
	color: #6a6a6a;
	margin: 10px 0;
}

.overview .overview_container .title h3 {
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.5px;
	word-spacing: 2px;
	margin: 0;

	font-weight: 700;
	margin-bottom: 10px;
}

.overview .overview_card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* flex: 1; */
	/* width: 500px; */
	margin: 10px;
}

.overview .overview_input {
	/* flex: 1; */
	box-shadow: 3px 7px 8px -2px rgb(0 0 0 / 15%);
	/* height: 170px; */
	padding: 15px;
	transition: all 0.3s ease-in-out;
	border-radius: 12px;
	border: 1px solid #ebebeb;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.overview .overview_input .image_text {
	display: flex;
	flex: 0.7;
}

.overview .overview_input .image_text img {
	width: 150px;
	height: 130px;
	background-position: center;
	background-size: cover;
}

.overview .overview_input .image_text .text-item {
	margin: 10px;
	text-align: justify;
}

.overview .overview_input .image_text .text-item h3 {
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 600;
	color: #271d42;
}

.overview .overview_input .image_text .text-item p {
	font-size: 10px;
	text-transform: capitalize;
	letter-spacing: 1px;
	color: #616161;
	/* line-height: 1px; */
	margin-top: 20px;
}

.overview .overview_input .time_date {
	display: flex;
	flex-direction: column;
	flex: 0.3;
	justify-content: space-between;
}

.overview .overview_input .time_date h3 {
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 600;
	color: #271d42;
}

/* //Invoice */
#invoices #dateofpara {
	display: block;
	flex-direction: columns;
}

#invoices #dateofaddresswc {
	position: relative;
	top: 27px;
	left: 0px;
}

#invoices .time_date button {
	padding: 4px 10px;
	background-color: #03a9f4;
	color: #ffff;
	border-radius: 20px;
	border: none;
	font-size: 12px;
}

/* //setting form */
#profile_page {
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
	margin-top: 1px;
}

.profile__name {
	font-size: 25px;
}

.profile__page__div {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
}

.navItem {
	display: flex;
	align-items: center;
	width: 60%;
	background-color: #03a9f4;
	margin: 10px 0;
	justify-content: space-between;
	border-radius: 12px;
	padding: 10px;
	cursor: pointer;
}

.navItem:hover {
	opacity: 0.8;
}

.navItem>p {
	margin: 0;
	color: #fff;
	font-size: 17px;
}

#profile_page .settings_form {
	display: block;
	max-width: 500px;
	width: 100%;
	margin: auto;
	/* text-align: left; */
	border-radius: 10px;
	margin-top: 10px;
	margin-bottom: 40px;
	box-shadow: 0px 8px 18px -6px rgb(0 0 0 / 30%);
	padding: 20px;
}

#profile_page .profile__avatar {
	/* align-self: center; */
	background-color: #f9fcff;
	background-image: linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%);
	width: 80px;
	height: 80px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile__avatarInitial {
	font-size: 30px;
	margin: 0;
}

#profile_page .gutter {
	padding-left: 10px;
	padding-right: 10px;
}



#profile_page .gutter .profile_heading {
	font-size: 21px;
	text-transform: capitalize;
	font-weight: 300;
	color: #6a6a6a;
}

#profile_page .gutter .small_heading {
	font-size: 21px;
	text-transform: capitalize;
	font-weight: 600;
	color: #6a6a6a;
}

#profile_page .profile_info .input_group {
	position: relative;
	display: block;
	width: 100%;
	margin: 18px 0px;
	padding: 0px 10px;
	/* z-index: 1; */
}

#profile_page .profile_info label.static {
	position: relative;
	left: 0;
	margin-bottom: 1px;
}

#profile_page .profile_info .input_group .input {
	position: relative;
	display: block;
	width: 100%;
	min-height: 40px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 14px;
	font-weight: 400;
	color: #585858;
	background: 0;
	z-index: 1;
}

.input_group .button__small {
	font-size: 11px;
	padding: 7px 40px;
	text-transform: capitalize;
	background-color: var(--theme);
	border: 0;
	border-radius: 8px;
	color: #fff;
}

#profile_page .float_wrapper {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
}

#profile_page .float_wrapper .update_button {
	position: absolute;
	right: 10px;
	bottom: 10px;
	border: none;
	padding: 0px 15px;
	border-radius: 20px;
	background-color: #03a9f4;
	color: #ffff;
}

/* //Gift card */
#gift {
	width: 100%;
	margin: auto;
	justify-content: center;
	text-align: center;
}

#gift .overview_container .heading h3 {
	font-size: 21px;
	text-transform: capitalize;
	font-weight: 300;
	color: #6a6a6a;
	margin-top: 10px;
}

#gift .overview_container .title h3 {
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.5px;
	word-spacing: 2px;
	margin: 0;

	font-weight: 700;
	margin-bottom: 40px;
}

#gift .gift_container {
	width: 80%;
	margin: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

#gift .gift_container .gift_input {
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
	padding: 4px;
	margin: 10px;
	border-radius: 10px;
	height: auto;
}

#gift .gift_container .gift_input .gift_button {
	margin-top: 10px;
	margin: 10px;
}

#gift .gift_container .gift_input .gift_button .Use_button {
	padding: 4px 21px;
	border: none;
	border-radius: 20px;
	background-color: #2196f3;
	color: #ffff;
	margin-right: 10px;
	font-size: 14px;
}

#gift .gift_container .gift_input .gift_button .Send_button {
	padding: 0px 21px;
	border-radius: 20px;
	box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35);
	color: #000;
	background: #fff;
	color: #737373;
	font-weight: 500;
	border: 2px solid #fff;
}

#gift .gift_container .gift_input .gift_button .Use_button:hover {
	transform: translateY(-3px);
	box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35);
	cursor: pointer;
	transition: all 0.15s ease-in;
}

#gift .gift_container .gift_input .gift_button .Send_button:hover {
	transform: translateY(-3px);
	box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35);
	cursor: pointer;
	transition: all 0.15s ease-in;
}

/* //Footer */
.user_profile_footer {
	width: 90%;
	margin: auto;
	background: #0f1d28;
	min-height: 50px;
	/* margin-top: 31rem; */
	color: #ffff;
	position: fixed;
	bottom: 0;
	/* z-index: 100; */
}

.footer_container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	text-align: center;
}

.cursor {
	cursor: pointer;
	color: lightblue;
}

.cursor:hover {
	color: white;
	transition: .2s ease-in;
}

.footer_warapper p {
	color: #ffffff;
	font-weight: 400;
	font-size: 10px;
	letter-spacing: 0.5px;
	padding-top: 16px;
}

#ashvi_design {
	color: #596a76;
}

#navigationuser {
	display: none;
}

.mobile-view {
	background-color: #2b2c36 !important;
	/* Change this to your desired color */
}

/* Pagination Styles */
.overview_user_page_pagination {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	float: right;
	margin-bottom: 30%;
}

.overview_user_page_pagination button {
	background-color: #f2f2f2;
	border: none;
	color: #333;
	padding: 8px 16px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	margin: 0 5px;
	border-radius: 4px;
	transition: background-color 0.3s;
}

.overview_user_page_pagination button.active {
	background-color: #333;
	color: #fff;
}

.overview_user_page_pagination button:hover {
	background-color: #ddd;
}

.userbooking {
	display: none !important;
}

ul#tabs_control .userbooking li.withicon a {
	font-weight: 500;
	background: #03a9f4;
	border-radius: 25px;
	padding: 5px 15px;
	color: #fff;
	top: 5px;
}

#imagebooking {
	position: fixed;
	top: 600px;
	right: 10px;
	z-index: 100;
}

#buycard {
	padding-top: 60px;
}

#gift_vard_buy {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

/* #gift_card_buy_input{
    width: 22%;
} */
#buy_gift_card_input {
	width: 25%;
	margin: auto;
}

#buy_gift_card_image img {
	width: 100%;
	background-size: cover;
	background-position: center;
}

.gift_button h3 {
	font-size: 15px;
	text-align: start;
}

.status_booking {
	/* text-align: start; */
	font-size: 12px;
}

.status_booking h3 {
	font-size: 15px;
}

.rating-feedback-section {
	/* background-color: #f5f5f5; */
	padding: 10px;
	/* border: 1px solid #ccc; */
	border-radius: 5px;
	/* margin-top: 10px; */
	width: 100%;
	margin: auto;
	box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35);
	/* position: relative;
    top: -164px; */
}

.rating-feedback-section h3 {
	font-size: 18px;
	/* margin-bottom: 10px; */
}

.rating-feedback-section input[type="number"] {
	width: 60px;
	padding: 5px;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.rating-feedback-section textarea {
	width: 100%;
	padding: 1px;
	border: 1px solid #ccc;
	border-radius: 5px;
	/* margin-bottom: 10px; */
}

.rating-feedback-section button {
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 12px;
}

.rating-feedback-section button:hover {
	background-color: #0056b3;
}

/* //Membership profile */

.update_membership {
	display: flex;
	text-align: center;
	justify-content: center;
}

.update_membership button {
	border: none;
	padding: 2px 4px;
	margin-left: 15px;
	color: #007bff;
	background-color: transparent;
}

.membership_container {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin: auto;
	background-color: #ffff;
}

.gold__text {
	background: linear-gradient(319deg, #faa499 0%, #f7dd85 37%, #ffc55c 100%);

	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	/* Set the text color to transparent */
	/* color: transparent; */
}

.silver__text {
	background: linear-gradient(315deg, #bdcad9 0%, #e1dada 74%);

	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

.membership_update {
	/* background-color: chocolate; */
	background-color: #f2f2f2;
	/* box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35); */
	padding: 0 10px;

	border-radius: 10px;
	margin: 10px;
}

.membership_update:nth-child(1) {
	background-color: #fff;
}

.membership_update:nth-child(2) {
	background-color: #ffff;
}

.membership_update .heading {
	display: flex;
	justify-content: space-between;
}

.membership_update .heading h3 {
	font-weight: 700 !important;
}

.membership_update .heading p {
	font-weight: 700 !important;
	padding-top: 20px;
}

.membership_update .conetnt {
	display: flex;
	justify-content: space-between;
}

.membership_update .conetnt h3 {
	font-size: 12px;
}

.mebershi_update_button {
	display: flex;
	justify-content: space-between;
	margin-left: 50px;
	font-size: 12px;
}

.mebershi_update_button h3 {
	font-size: 15px;
	color: #03a9f4;
}

#membership_button_payment .button {
	text-align: start;
	/* background-color: red; */
	justify-content: start;
	padding: 4px 4px;
	position: relative;
	left: -50px;
	top: -10px;
}

/* Add these styles to your existing CSS file or create a new one */

.modal_send_gift_card {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal_send_gift_card .modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 400px;
	width: 100%;
}

.modal_send_gift_card .close {
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 18px;
	color: #555;
}

.modal_send_gift_card h2 {
	margin-bottom: 20px;
}

.modal_send_gift_card label {
	display: block;
	margin-bottom: 8px;
	text-align: start;
}

.modal_send_gift_card input,
textarea {
	width: 100%;
	padding: 8px;
	margin-bottom: 16px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.modal_send_gift_card button {
	background-color: #03a9f4;
	color: #fff;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

/* .modal_send_gift_card button:hover {
	background-color: #45a049;
} */

/* //model gift card */

.model_card_gift_container {
	position: fixed;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	width: 40%;
	height: auto;
	margin: auto;
}

.model_card_gift {
	text-align: center;
	margin-bottom: 20px;
}

.model_card_gift h3 {
	margin: 0;
	font-size: 1.5em;
}

.model_card_gift p {
	margin: 0;
	color: #888;
}

.membership_update_button button {
	/* background-color: #4caf50; */
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-bottom: 15px;
}



.membership_update_buttons button {
	position: relative;
	top: -60px;
}

.active_membership_icon {
	position: relative;
	top: -370px;
	left: -40px;
	float: right;
}


.model_card_gift_container .close {
	cursor: pointer;
	float: right;
	font-size: 30px;
	position: relative;
	/* top: -20px; */
}

.membership_model_item {
	display: flex;
	justify-content: space-around;
	margin: 15px 0;
}

.membership_model_item p {
	color: #000;
}

.model_card_gift {
	/* background-color: #fff; */
	padding: 20px;
	border-radius: 10px;
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.membership_model_item div {
	margin-left: 15px;
}

.membership_model_item p {
	margin: 5px 0;
	text-align: justify;
}

.careImg {
	width: 100%;
}

/* Increase the size of the icons */
/* .membership_model_item svg {
    font-size: 35px;
} */

#icon_mwembership {
	font-size: 50px;
}

#icon_mwembership1 {
	font-size: 35px;
}

/* Add specific styles for your icons if needed */

/* Add specific styles for your icons if needed */

/* Container for the membership card */
.membership_containers {
	position: relative;
}

.image_membership {
	/* position: relative; */
	/* position: absolute; */
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
}

/* Style for the membership image */
.image_membership img {
	width: 100%;
	max-width: 500px;
	height: auto;
	border-radius: 8px;
	max-height: 250px;
}

/* Style for the active membership icon */
.active_membership_icons {
	position: absolute;
	/* top: -10px;
	right: -5px; */
	top: 8px;
	right: 15px;
}

.active_membership_icons img {
	width: 150px;
	/* Make the image fill the container width */
	/* max-width: 100px; */
	/* Set a maximum width if needed */
	height: 100px;
	/* Maintain the aspect ratio */
	/* border-radius: 8px; */
}

/* Style for the membership buttons */
.membership_buttons {
	margin-top: 10px;
	position: absolute;
	bottom: 12%;
	left: 35%;
	width: 30%;
}

/* Button style (customize as needed) */
.membership_buttons button {
	padding: 3px 13px;
	background-color: #03a9f4;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	/* margin-left: 40px; */
	margin-bottom: 10px;
}

/* Membership expire paragraph */
.membership_buttons p {
	position: absolute;
	font-size: 12px;
	width: 200%;
	left: -50%;
	bottom: -5px;
}

.smallDetail {
	font-size: 12px !important;
}
.notiAlert{
	background-color: red;
    color: white;
    border-radius: 50%;
    padding: 1px;
    font-size: 10px;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin: -10px 0 0 5px;
}


@media (max-width: 1084px) {


	.overview_card {
		width: 100%;
	}
}


#addons_text {
	padding: 10px;
	/* Optional: Adds some padding around the content */
	max-width: 100%;
	/* Ensures the content does not exceed the width of its container */
	box-sizing: border-box;
	/* Ensures padding is included in the width calculation */
	word-wrap: break-word;
	/* Breaks long words to prevent overflow */
	word-break: break-word;
	/* Ensures words break if necessary */
	overflow-wrap: break-word;
	font-size: 10px;
}

#addons_text h3 {
	font-size: 14px;
}

@media (max-width: 992px) {
	/* .toggle_button{
		display: none !important;
	} */
	.support-box{
		top: -50px;
		position: relative;
	}
	.profileSpace {

		background: url(../../../assets/img/9496304.png);
		background-size: 100% 100%;
		background-attachment: fixed;
		width: 100%;
		height: 100% !important;
		overflow-y: scroll;
		position: absolute;
		right: 0;
		bottom: 0;
		top: 10%;
		zoom: 1;
	}

	#user_profile_page {
		width: 100% !important;
		margin: auto;
		position: absolute;
		top: 100px;
		right: 0;
		left: 0;

	}



	.notification__item {
		justify-content: center;
		text-align: center;
		width: 100%;
		height: auto;
		border-radius: 10px;
		margin: 20px 0;
		padding: 10px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}

	.model_card_gift_container {
		width: 90%;
		margin: auto;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		z-index: 1000;
		height: auto;
		margin: auto;
		font-size: 10px;
	}

	.sidebar_tab {
		background-color: transparent !important;
		padding: 30px 0px;
		top: 60px;
		/* display: none; */
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100;
		height: 0vh;
	}

	.mobile-view {
		background-color: #2b2c36 !important;
		/* Change this to your desired color */
		width: 350px;
		height: 100%;
		position: fixed;
		z-index: 1000;
	}

	.sidebar_tab ul#tabs_control {
		display: none;
	}

	.mobile-view ul#tabs_control {
		display: block;
	}

	.mobile-view ul#tabs_control .item {
		/* display: block; */
		padding: 1px 50px;
		justify-content: left;
		/* align-items: start; */
	}

	ul#tabs_control li {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		display: block;
		cursor: pointer;
		color: #fff;
		font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
			Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
			"Segoe UI Symbol";
	}

	.footer_container {
		display: flex;
		flex-direction: column !important;
		justify-content: space-around;
		text-align: center;
	}

	.inner {
		justify-content: center;
		text-align: center;
		/* margin-top: -67px !important; */
	}

	#overview_page_container {
		width: 100% !important;
		margin: auto;
	}



	.overview .overview_container {
		width: 100% !important;
		margin: auto;
		justify-content: center;
		margin-top: 20px;

	}

	.overview .overview_input .image_text img {
		width: 98px !important;
		height: 78px !important;
		background-position: center;
		background-size: cover;
	}

	#navigationuser .toggle_buttons {
        position: fixed;
        top: 10px;
        right: 15px;
        width: 45px;
        /* height: 32px; */
        /* background-image: url(../img/menu.png); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        filter: invert(1);
        background: #ffa681;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 50%;
    }

	#navigationuser {
		display: block !important;
	}

	#navigationuser .toggle_buttons img {
		width: 25px;
	}

	.userbooking {
		display: block !important;
	}

	#imagebooking {
		/* display: none; */
		top: 80% !important;
		right: 20px;
	}

	/* #imagebooking img {
		width: 49px;
		height: 49px;
	} */
	.profile__divs {
		flex-direction: column;
	}


	.user_profile_footer {
		width: 100%;
		height: 15vh;
		padding: 1px;
		margin: auto;
		background: #0f1d28;

		color: #ffff;

		bottom: 0;

	}

	.notification__view {
		width: 100% !important;
	}

	.notification__item {
		width: 100% !important;
	}

	.favorite__item {
		width: 100% !important;
	}

	#my_appointments {
		width: 100% !important;
		zoom: 0.8 !important;
	}

	#my_appointments .card {
		zoom: 0.8 !important;
	}

	.memberships {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 767px) {
	.profileSpace {
		zoom: 1;

	}

	#my_appointments .card {
		zoom: 0.7;
	}

	.my_appointments {
		width: 100%;
		/* Set width to 100% for 1 card in a row on mobile */
	}

	#my_appointments .card {
		flex-basis: calc(100% - 20px) !important;
		/* Two cards per row with padding */
	}

	.membership_buttons button {
		margin: auto;
		font-size: 12px;
		margin-bottom: 10px;

	}

	.membership_container {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: auto;
	}

	.membership_update .conetnt h3 {
		font-size: 10px;
	}

	.membership_update .conetnt p {
		font-size: 10px;
	}

	.notification__titleView {
		display: block !important;
	}
	.careImg {
		width: 100%;
		margin: auto !important;
		height: auto !important;
		background-size: cover;
		padding: 10px !important; 
	}
}



@media (max-width: 375px) {
	.membership_buttons button {
		font-size: 9px !important;
	}

	#my_appointments .card {
		zoom: 0.6 !important;
	}
}