.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding: 0 !important;
}

banner a {
  color: initial;
}

a:hover,
a:focus {
  color: initial;
  outline: none;
  text-decoration: none;
}

body {
  font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}

::-webkit-scrollbar-thumb:active {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: #616161;
}

.gutter {
  padding-left: 10px;
  padding-right: 10px;
}

/* buttons */
.buttons_pane {
  position: relative;
  display: block;
  width: 100%;
}

.button {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0.9em 3.5em;
  border: 0;
  background: #2196f3;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 2px;
  border-radius: 25px;
  box-shadow: -1px 1px 5px -3px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  transition: all 0.15s ease-in;
  border: 2px solid #fff;
}

.button.small {
  font-size: 11px;
  padding: 0.7em 3em;
  text-transform: capitalize;
}

.button:focus {
  outline: 0;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 10px -5px rgb(0 0 0 / 25%);
}

.button.ghost {
  background: #fff;
  color: #737373;
  font-weight: 500;
  border: 2px solid #fff;
}

.button.primary {
  background: #fff;
  color: #141a28;
}

.button[data-state] {
  padding-left: 4.5em;
  padding-right: 2.5em;
}

.button[data-state="loading"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 0.6em);
  left: 1em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  animation-name: loading_rotate;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loading_rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button[data-state="done"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  left: 1em;
  width: 16px;
  height: 7px;
  border: 2px solid #fff;
  border-right: 0;
  border-top: 0;
  transform: rotate(-40deg);
}

.button[data-state="failed"]::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 7.5px);
  left: 1em;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* background: url('../img/icons/wrong.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.float_wrapper {
  position: relative;
}

.float_wrapper::after {
  content: '';
  display: table;
  clear: both;
}

#navigation {
  position: sticky;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-top: 0;
  padding-top: 10px;
  z-index: 11;
  padding-bottom: 10px;
  background: #162b3c;
  box-shadow: 0px 3px 4px #00000045;
}

#navigation .logo {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 260px;
  /* padding-left: 5px; */
}

#navigation .logo img {
  width: 200px;
  margin-top: -5px;
}

#navigation .logo .icon {
  position: absolute;
  top: -8px;
  left: 0;
  width: 55px;
  height: 55px;
  background: url('../img/logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#navigation .logo .name {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 6px;
  position: relative;
  display: block;
  color: #fff;
  top: -6px;
}

#navigation .logo .name small {
  text-transform: capitalize;
  margin-top: 0;
  position: relative;
  font-size: 12px;
  display: inline-block;
  border-top: 1px solid rgb(255 255 255 / 62%);
  padding-top: 0px;
  letter-spacing: 0px;
}

#navigation .nav_bar {
  position: relative;
  display: block;
  width: auto;
  float: right;
  list-style: none;
  padding: 0px 0px;
  margin: 0;
}

#navigation .nav_bar li {
  position: relative;
  display: block;
  float: left;
  margin-left: 15px;
}

#navigation .nav_bar li.withicon {
  margin-left: 10px;
}

#navigation .nav_bar li.withicon a {
  font-weight: 500;
  background: #03a9f4;
  border-radius: 25px;
  padding: 5px 15px;
  color: #fff;
  top: 5px;
}

#navigation .nav_bar li .icon {
  position: absolute;
  top: calc(50% - 8px);
  left: 2px;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#navigation .nav_bar li a {
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 300;
  text-transform: capitalize;
  padding: 11px 0px;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

#navigation .nav_bar li:hover a {
  /* text-shadow: 0px 0px 10px #cee5ff; */
  /* color: #03a9f4; */


}

#navigation .nav_bar li::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -10px;
  left: 0;
  /* background: #2196f3; */
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.2s ease-in-out;
}

#navigation .nav_bar li:hover::after {
  opacity: 1;
  transform: translateX(0px);
}

#navigation .nav_bar li.withicon:hover::after {
  display: none;
}

#banner {
  position: relative;
  display: block;
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url('../img/masseur-doing-massage-backbone-man-body-spa-salon-beauty-treatment-concept.jpg'); */
  background-size: cover;
}

#banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(0deg, black -13%, #00000033); */
}

.video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.video-foreground,
.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: scale(1.15);
}

#video_section {
  margin-left: 50px;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

#banner .head {
  position: absolute;
  display: block;
  width: 50%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: left;
}

#banner .head h1 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
}

#banner .head h1 span {
  display: block;
  color: #2196f3;
}

#banner .head h3 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.3px;
  word-spacing: 1px;
}

#banner .head .button {
  margin-top: 35px;
  padding-bottom: 12px;
  padding-top: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  margin-left: 5px;
}

.button.hollow {
  background: none;
  border: 2px solid #fff;
}

#banner .button.primary {
  background: #2196f3;
  border: 2px solid #00e2ff;
}

#banner .arrow_down {
  position: absolute;
  bottom: 32px;
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  background: url('../img/arrow_down.svg');
  background-position: 7.5px 9px;
  background-repeat: no-repeat;
  background-size: 45%;
  cursor: pointer;
  z-index: 2;
}

#banner .arrow_down::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: calc(50% - 1px);
  width: 1px;
  height: 50px;
  background: linear-gradient(45deg, white, transparent);
}

#types {
  position: relative;
  display: block;
  width: 100%;
  /* padding-left: 13%; */
}




#detailblogitem>h3 {
  position: relative;
  font-size: 30px !important;
  color:var(--primary);
}



#types #detailblogitem>p p {
  position: relative;
  text-align: center;
  right: 2.2rem;
  top: -40px;
  width: 65rem !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}









.type {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 0% !important;
}

#types .item_wrapper {
  padding: 10px;
}

#types .item_wrapper .bg {
  position: relative;
  display: block;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  padding-top: 65%;
}
.disabled-button {
  background-color: #d3d3d3; /* Gray background */
  color: #ffff; /* Light gray text */
  border: 1px solid #aaaa; /* Light gray border */
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
  opacity: 0.9; /* Slightly faded effect */
  padding: 5px;
  border-radius: 5px;
}

.disabled-button:hover {
  background-color: #d3d3d3; /* Ensure background color remains the same */
  color: #a9a9a9; /* Ensure text color remains the same */
  border: 1px solid #a9a9a9; /* Ensure border color remains the same */
}

#types .item_wrapper .content {
  position: relative;
  display: block;
  width: 100%;
  min-height: 23px;
  margin: 10px;
}

#types .item_wrapper.first .bg {
  min-height: 350px;
  margin-top: -80px;
}

#types .item_wrapper.second .bg {
  min-height: 250px;
}

#types .item_wrapper.third .bg {
  min-height: 350px;
  margin-top: 0px;
}

#types .button {
  position: relative;
  float: right;
  margin-right: 20px;
  text-transform: uppercase;
  padding-top: 11px;
  padding-bottom: 8px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 9px;
  color: #000;
  background: #fff;
  border: 1px dashed #afafaf;
  box-shadow: none;
  /* position: absolute; */
  bottom: 0;
  /* left: 0; */
}

#types .button:hover {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
  box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 0.2);
}

#types .content h3 {
  font-size: 18px;
  font-weight: 800;
}

#types .content p {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
  word-spacing: 3px;
  color: #616161;
  margin-bottom: 20px;
}

#about {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 50px;
  /* padding: 40px 0px; */
}

#about .item {
  position: relative;
  display: block;
  width: 100%;
}

#about .col-sm-4:first-child .item::after {
  display: none;
}

#about .item::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 60%;
  background: #d5d5d5;
  transform: translateY(-50%);
}

#about .item .content {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  background: #ffffff;
  padding: 50px 20px;
}

#about .item .content .icon {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(1);
  opacity: 0.75;
}

#about .item .content h3 {
  font-size: 18px;
  color: #454545;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  word-spacing: 3px;
  margin-top: 12px;
  margin-bottom: 22px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#about .item .content p {
  font-size: 14px;
  color: #000000ab;
  margin-bottom: 20px;

}

#about .item .content a {
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 5px 0px;
}

#about .item .content a:hover {
  opacity: 1;
}

#about .item .content a::after {
  content: '';
  width: 10px;
  height: 1px;
  background: #000;
  position: absolute;
  top: calc(50% - 0.5px);
  left: calc(100% + 5px);
  transition: all 0.2s ease-in-out;
}

#about .item .content a::before {
  position: absolute;
  top: calc(50% - 3px);
  left: calc(100% + 12px);
  content: '';
  width: 7px;
  height: 6px;
  background: url('../img/arrow_down.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
  filter: invert(1);
}

#about .item .content a:hover::before {
  transform: translateX(10px) translateY(0.5px) rotate(-90deg) scale(1.5);
}

#about .item .content a:hover::after {
  transform: translateX(10px);
}

#about .left_half {
  position: relative;
  display: block;
  width: 100%;
}

#about .about_content {
  position: relative;
  display: block;
  padding: 15px 50px;
  text-align: left;
}

#about .about_content span {
  font-size: 17px;
  text-transform: capitalize;
}

#about .about_content h3 {
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 700;
  color: #263d4a;
}

#about .about_content p {
  font-size: 14px;
  font-weight: 400;
  color: #394a54;
  margin-bottom: 32px;
}

#about .about_content .button.primary {
  background: #2196f3;
  color: #fff;
  border: 1px solid #00e2ff;
  margin-right: 10px;
}

#about .about_content .button.ghost {
  background: none;
  color: #375769;
  border: 1px solid #54707f;
}

#about .about_content .bg {
  position: absolute;
  left: 0;
  margin-top: 32px;
  display: inline-block;
  width: 100%;
  height: 450px;
  /* background: url('../img/about_bg.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#about .left_half .img-responsive {
  width: 500px;
}

#classes {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 85px;
  padding: 0 35px;
}

#classes .item_wrapper {
  padding: 5px;
  transition: all 0.15s ease-in;
}

.class_brief .text.content {
  min-height: 80px;
}

.heading {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
}

.heading h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 800;
  color: #0f1538;
}

.heading p {
  font-size: 15px;
  justify-content: center;
  /* text-transform: capitalize; */
  font-weight: 400;
  letter-spacing: 0.5px;
  word-spacing: 3px;
  color: #4b4b4b;
}

#classes .item_wrapper .item {
  transition: all 0.15s ease-in-out;
}

#classes .item_wrapper .item:hover {
  transform: translateY(-5px) scale(1.005);
  box-shadow: 2px 15px 15px -10px rgba(0, 0, 0, 0.15);
}

.class_brief.card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 3px 6px 7px -7px rgb(0 0 0 / 0.2);
  border: 1px solid #f8f8f8;
  padding-bottom: 15px;
}

.class_brief .text {
  position: relative;
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 0px 15px;
}

.class_brief .text h3 {
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 16px;
  text-transform: capitalize;
  color: #1e1e21;
  margin: 0;
  margin-top: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.class_brief .text p {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  color: #646464;
  margin: 0;
  line-height: 20px;
  margin-top: 5px;
}

.class_brief .text .star_ratings {
  float: right;
}

.class_brief .text span {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #272727;
  text-transform: capitalize;
  margin: 0;
  padding-left: 20px;
  float: left;
}

.class_brief .text span::after {
  content: '';
  position: absolute;
  /* background: url('../img/icons/clock.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 16px;
  width: 16px;
  top: 2px;
  left: 0;
  opacity: 0.75;
}

.class_brief .text .price {
  position: relative;
  display: inline-block;
  font-weight: 900;
  font-size: 25px;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
  margin-top: 4px;
}

.class_brief .text .price sup {
  color: #f95428;
}

.class_brief .text .anchor {
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  text-transform: capitalize;
  color: #32414a;
  margin: 0;
  background: 0;
  border: 1px solid #a4b2b9;
  border-radius: 20px;
  padding: 5px 27px;
}

.class_brief .bg {
  position: relative;
  display: block;
  width: auto;
  padding-top: 75%;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  margin-bottom: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#brief {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 70px;
}

.members {
  margin-top: 0 !important;
}

#brief::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(http://localhost:3000/static/media/777d847….72dc8f2….jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
}

#brief::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d6edff;
    opacity: 0.9;
}

#brief .content {
  position: relative;
  display: block;
  width: 100%;
  z-index: 3;
  padding: 10px;
}

#brief .content.heading h3 {
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 800;
  color: #29251a;
}

#brief .content h3 span {
  color: #2196f3;
}

#brief .content.heading h5 {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 32px;
  color: #29251a;
}

#brief .content p {
  font-size: 13px;
  color: #232018;
  /* margin-left: -2.5rem; */
}

#brief .content .button {
  background: #2196f3;
  color: #fff;
  border: 1px dashed #fff;
}

#brief .video_wrapper {
  position: relative;
  display: block;
  width: 118%;
  padding-left: 25px;
}

#brief .video_wrapper video {
  border-radius: 8px;
  position: relative;
  display: block;
  width: 110%;
  box-shadow: 7px 11px 15px -7px rgb(0 0 0 / 35%);
}

#brief hr.light {
  border-color: #d4cebe;
}

#brief .content.heading h3.dancing {
  text-align: center;
  font-size: 22px;
}

#brief .content.heading p {
  text-align: center;
}

#brief .infobox .item_wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 25px;
}

#brief .infobox .item {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

#brief .infobox .text {
  position: relative;
  padding-left: 70px;
}

#brief .infobox .text h3 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: #423c2e;
  letter-spacing: 0.5px;
  word-spacing: 3px;
}

#brief .infobox .text p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  word-spacing: 3px;
  color: #5d5541;
}

#brief .infobox .icon {
  position: absolute;
  top: 29;
  left: 0;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #2196f3;
}

#brief .infobox .icon span {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#brief .infobox .icon::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 1px dashed #2196f3;
  border-radius: 50%;
}

#brief .content.heading .eff {
  position: absolute;
  bottom: 7px;
  left: calc(50% - 35px);
  width: 70px;
  background: #2196f3;
  height: 3px;
}

#testimonials {
  position: relative;
  display: block;
  width: 100%;
  top: -110px;
  /* margin-top: 40px;
  margin-bottom: 60px; */
}

#testimonials .item_wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
}

#testimonials .item_wrapper .item {
  position: relative;
  display: block;
  width: 100%;
}

#testimonials .item_wrapper .item .avatar {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 80px;
  height: 80px;
  background: #000;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#testimonials .item_wrapper .item.right .avatar {
  right: initial;
  left: 0;
}

#testimonials .item_wrapper .item .content {
  position: relative;
  display: block;
  width: 100%;
  padding-right: 90px;
}

#testimonials .item_wrapper .item.right .content {
  padding-right: 0px;
  padding-left: 90px;
}

#testimonials .item_wrapper .item .content .inner {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 20%);
}

#testimonials .item_wrapper .item .content .inner::after {
  content: '';
  position: absolute;
  display: block;
  top: 25px;
  right: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  filter: drop-shadow(10px 0px 5px rgb(0 0 0 / 35%));
}

#testimonials .item_wrapper .item.right .content .inner::after {
  right: 0px;
  left: -7px;
  transform: rotate(180deg);
}

#testimonials .item_wrapper .item.right {
  margin-top: 45px;
}

#testimonials .item_wrapper .item h3 {
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 300;
  color:black;
}

#testimonials .item_wrapper .item p {
  font-size: 13px;
  color: #666;
}

#talent {
  position: relative;
  display: block;
  background: #f5f5f5;
  min-height: 400px;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
}

#talent .big_head span {
  color: #03A9F4;
}

#talent .big_head h3 {
  color: #232323;
  text-transform: capitalize;
}

#talent .big_head p {
  color: #a0a1a2;
}

#talent .wrapper {
  position: relative;
  display: block;
  padding: 30px;
}

#talent .wrapper .item {
  position: relative;
  display: block;
  min-height: 200px;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
}

#talent .wrapper .item::after {
  content: '';
  position: absolute;
  background: #f5f5f5;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #2196f3;
  right: -41px;
  top: 10px;
}

#talent .wrapper .item::before {
  content: '';
  position: absolute;
  border-color: transparent transparent transparent #ffffff;
  border-width: 9px 0px 10px 15px;
  border-style: solid;
  right: -12px;
  top: 10px;
}

#talent .wrapper .item.item_right::before {
  border-width: 0px 0px 0px 0px;
}

#talent .wrapper .item .tri {
  position: absolute;
  border-color: transparent #ffffff transparent transparent;
  border-width: 15px 20px 15px 0px;
  border-style: solid;
  left: -12px;
  top: 5px;
}

#talent .wrapper .item.item_right::after {
  left: -39px;
  top: 10px;
  right: 0;
}

#talent .wrapper .item .bg {
  position: absolute;
  top: calc(50% - 24px);
  right: 15px;
  display: block;
  background-image: url('../img/works/visit.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 48px;
  width: 48px;
  background-color: #ffffff;
  filter: grayscale(0.8);
}

#talent .wrapper .item .inner_wrapper {
  position: relative;
  display: block;
  background: #ffffff;
  min-height: 200px;
  width: 100%;
  padding: 20px;
}

#talent .inner_wrapper .content {
  position: relative;
  display: block;
  margin-top: 10px;
  padding-right: 60px;
}

#talent .inner_wrapper .content h3 {
  position: relative;
  display: block;
  font-weight: 800;
  font-size: 16px;
  color: var(--primary);
  text-transform: capitalize;
  margin-top: 10px;
}

#talent .inner_wrapper .content h4 {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #e06443;
  text-transform: capitalize;
  letter-spacing: 3px;
  margin-top: 5px;
}

#talent .inner_wrapper .content p {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #505050;
  margin-top: 20px;
  line-height: 1.3em;
}

#talent .inner_wrapper .social {
  position: relative;
  display: block;
  margin-top: 55px;
}

#talent .inner_wrapper .social_icon {
  position: relative;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

#talent .inner_wrapper .social_icon img {
  height: 100%;
  width: 100%;
}

#talent .big_wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 80px 0px;
  /* margin-top: 70px; */
  padding-top: 0px;
}

#talent .big_wrapper .line {
  position: absolute;
  display: block;
  left: 50%;
  background: #2196f3;
  width: 3px;
  height: 100%;
  top: 50px;
}

#talent .buttons_pane {
  text-align: center;
  margin: 0;
  margin-left: -9px !important;
}

#talent .button {
  background: #2196f3;
  border-radius: 50px;
  padding: 15px 50px;
  color: #fff;
  border: 0;
  font-size: 14px;
  box-shadow: 3px 8px 6px -5px #565656;
}

#banner .infobox_big .col-sm-4+.col-sm-4 .item {
  border-left: 1px solid #698096;
}

#talent .inner_wrapper .content h3 b {
  font-size: 25px;
  font-weight: 600;
}

#contactform {
  position: relative;
  display: block;
  width: 100%;
  background: #4e86ff;
  height: 755px;
}

#contactform .curve {
  position: relative;
  display: block;
  width: 100%;
  min-height: 250px;
  background: #f5f5f5;
  border-radius: 50%;
  top: -250px;
  transform: scale(1.5);
}

#contactform .heading {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
}

#contactform .heading h3 {
  color: #fff;
  font-size: 32px;
  text-transform: capitalize;
  font-weight: 800;
  margin-top: 12px;
}

#contactform .heading h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-spacing: 4px;
}

#contactform .card {
  margin-top: 80px;
  box-shadow: 0px 20px 20px -5px rgba(0, 0, 0, 0.15);
}

#contactform .card .bg {
  position: absolute;
  top: -10px;
  display: block;
  width: 100%;
  height: 532px;
  background: url('../img/tender-african-woman-relaxing-enjoying-healthy-spa-massage-with-oil.jpg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  left: -10px;
  bottom: -10px;
}

#contactform .curve_wrapper {
  position: absolute;
  display: block;
  width: 100%;
  overflow: hidden;
}

#contactform .heading.negative h3 {
  color: #000;
  text-align: left;
}

#contactform .button {
  background: #2196f3;
  color: #fff;
  border: 2px solid #00bcd4;
  border-radius: 25px;
}

#footer {
  position: relative;
  display: block;
  width: 100%;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#footer {
  margin: 0;
  position: relative;
  min-height: 250px;
  padding-top: 70px;
  padding-bottom: 70px;
  background: #080e12;
}

#footer .brand h3 {
  color: #939393;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 25px;
}

#footer .brand h3 span {
  font-weight: 300;
}

#footer .intro p {
  color: #ffffffc4;
  font-size: 12px;
  text-align: left;
  padding-right: 45px;
  font-weight: 300;
}

#footer .head {
  margin-bottom: 25px;
}

#footer .head h3 {
  color: #c1c1c1;
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

#footer .head h3 span {
  font-weight: 300;
}

#footer .nav ul {
  list-style: none;
  margin-left: -20px;
}

#footer .nav ul li {
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 0px;
  transition: all 0.2s ease-in;
}

#footer .nav ul li:hover {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 5px;
}

#footer .nav ul li::before {
  content: '';
  position: absolute;
  top: 8px;
  left: -20px;
  background: url('../img/arrow.png');
  background-size: contain;
  background-position: center;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  filter: invert(1);
}

#footer .nav ul li a {
  color: #9d9d9d;
  font-size: 13px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 45px;
  text-transform: capitalize;
  text-decoration: none;
}

#footer .nav ul li:hover a {
  color: #2a5175;
}

#footer .form .intro {
  padding-top: 25px;
  padding-bottom: 5px;
}

#footer .form .input {
  background: #e5e5e5;
  border: 0;
  min-height: 40px;
  padding-left: 15px;
  color: rgb(104 104 104);
  font-size: 12px;
  text-transform: capitalize;
  width: 100%;
}

#footer .form .button {
  border-radius: 0;
  border: 0;
  border-left: 0;
  margin-left: -4px;
  min-height: 40px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

#footer .form input:focus {
  outline: 0;
}

#footer .social {
  margin-top: 30px;
}

#footer .social .icon {
  display: inline-block;
  border: 1px solid;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  margin-left: 15px;
  text-align: center;
  filter: hue-rotate(-15deg);
  color: lightgrey;
}
#footer .social .icon:hover{
  color: white;
  transition: .3s ease-in-out;
  transform: translateX(-2px) translateY(-2px);
}

#footer .social .icon:first-child {
  margin-left: 0;
}

#footer .social .icon img {
  width: 75% !important;
  height: auto;
  top: 1px;
  position: relative;
  display: inline-block;
}

#footer_strip {
  background: #0f1d28;
  min-height: 50px;
  text-align: center;
}

.policy_footer {
  color: #ffff;
}

#footer_strip .copyright h3 {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.5px;
  padding:15px 0;
}

#footer_strip .copyright.pull-right h3,
#footer_strip .copyright.pull-right h3 b {
  color: #596a76;
}

#footer .infobox {
  margin-top: 35px;
}

#footer .infobox .icon {
  width: 19px;
  height: auto;
  float: left;
}

#footer .infobox .icon img {
  width: 100%;
  height: auto;
}

#footer .infobox .text p {
  padding-left: 34px;
  color: #ffffffc4;
  font-size: 13px;
  width: 100%;
  text-transform: none;
}

#provider {
    position: relative;
    display: block;
    width: 100%;
    background: #d6edff;
    top: -150px;
}

#provider .right_half {
  position: relative;
  display: block;
  width: 100%;
  height: 520px;
  /* background: url('../img/pexels-ivan-samkov-5659057.jpg'); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display:flex;
  align-items:center;
}

#provider .heading {
  position: relative;
  padding-right: 100px;
  margin-top: 100px;
  width: 41rem;
  padding-left: 9rem;
}

#download_app .heading {
  position: relative;
  display: block;
  padding-right: 100px;
  margin-top: 100px;
  width: 41rem;
  padding-left: 8rem;
}

/* 
#small_banner {
  position: relative;
  display: block;
  width: 100%;
  height: 500px;
  margin-top: 60px;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */


#small_banners .heads {
  background: #ebebeb82;
  height: 500px;
  padding: 79px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  position: relative;
  left: 48%;
}


#small_banner .head {
  background: #ebebebbd;
  height: 500px;
  padding: 79px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: relative;
  /* left: 48%; */
  /* width: 90%; */
}

#small_banner .head h3 {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.5em;
}

#small_banner .head h1 {
  font-size: 24px;
  font-weight: 800;
}


#small_banners .heads h3 {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.5em;
}

#small_banners .heads h1 {
  font-size: 16px;
  font-weight: 800;
  padding-top: 15px;
}








#alternate_post {
  position: relative;
  display: block;
  width: 100%;
}

#alternate_post .bg {
  position: relative;
  display: block;
  width: 100%;
  height: 500px;
  background: url('../img/pexels-cup-of-couple-6634174.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#alternate_post .heading {
  /* padding: 3px;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-left: 6rem;
  padding-top: 6rem; */

  justify-content: center;
  text-align: center;
  width: 100%;
  padding-left: 0rem;
  padding-top: 6rem;
  padding: 81px;
}

#member_cta {
  position: relative;
  display: block;
  width: 100%;
  min-height: 300px;
  background: #061623;
}

#member_cta h3,
#member_cta p,
#member_cta h2 {
  color: #fff;
}

.service_controls {
  position: absolute;
  display: block;
  height: 100%;
  left: 0;
  right: 0;
}

.service_controls .prev,
.service_controls .next {
  position: absolute;
  top: 50%;
  left: 0;
  background: #fff;
  width: 30px;
  height: 30px;
  transform: translateY(-30px);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 13%);
  border: 1px solid #ebebeb;
}

.service_controls .prev::after,
.service_controls .next::after {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background: url('../img/arrow.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.service_controls .next::after {
  transform: rotate(180deg);
}

.service_controls .next {
  left: initial;
  right: 0;
}

#small_banner .button.negative {
  background: #12183a;
}

ul.true {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 25px;
  text-align: left;
  /* padding-left: 15px; */
}

ul.true li {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 20px;
  margin-top: 10px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  color: #4b4b4b;
}

ul.true li::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 5px;
  border: 2px solid #000;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) translateY(-60%);
}

#gift_card {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

#profile_wizard_carousel {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 35px;
  padding: 50px;
  text-align: center;
}

#profile_wizard_carousel .illustration {
  position: relative;
  display: block;
  width: 100%;
  height: 350px;
  margin-bottom: 30px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

#profile_wizard_carousel .title {
  font-size: 25px;
  margin-bottom: 7.5px;
  display: block;
  text-transform: capitalize;
  color: #2c2e36;
}

#profile_wizard_carousel p {
  font-size: 13px;
  color: #535c60;
}

.pills {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pills::after {
  content: '';
  clear: both;
  display: table;
}

.pills li {
  width: 25%;
  position: relative;
  float: left;
  height: 5px;
  background: #cdcdcd;
  border-radius: 0;
  overflow: hidden;
}

.pills li .ico {
  position: absolute;
  top: -13px;
  right: calc(50% - 14px);
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #9ed4ff;
  z-index: 1;
  cursor: pointer;
}

.pills li .ico::after {
  content: '';
  position: absolute;
  top: 7px;
  left: 6px;
  width: 12px;
  height: 6px;
  opacity: 0;
  border: 2px solid #16629f;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

.pills li::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #2196f3;
  display: block;
  transition: all 0.1s ease-in-out;
}

.pills li[hold]::after {
  width: 50%;
}

.pills li[active]::after {
  width: 100%;
}

.pills li[hold] .ico {
  border-color: #2196f3;
}

.pills li[active] .ico::after {
  opacity: 1;
}

.section_group .section {
  display: none;
}

.section_group .section[active] {
  display: block;
}

#gift_card .section {

  width: 93%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 200px;
  background-color: #fff;
  padding: 80px;
  border-radius: 10px;
  box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);


}

.gender_holder {
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.gender_holder li {
  position: relative;
  display: block;
  width: calc(50% - 10px);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 0px;
  cursor: pointer;
  float: left;
  margin: 5px;
}

.gender_holder li .icon {
  position: relative;
  display: block;
  width: 100%;
  height: 46px;
  /* background-image: url('../img/male.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0;
}

.gender_holder li .text {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  color: #403939;
  text-transform: capitalize;
  font-size: 13px;
}

.gender_holder li .text.big {
  font-size: 18px;
  font-weight: 600;
}

.gender_holder li p {
  text-align: center;
}

.gender_holder li::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #8b8b8b;
}

.gender_holder li[active]::after {
  background: #2196f3;
}

.gender_holder li[active]::before {
  content: '';
  display: none;
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

.gender_holder li[active]::before {
  display: block;
}

#contact_us {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-top: 60px;
  /* background: url('../img/pexels-sergey-torbik-7365442.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}

#contact_us::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, black, #2196f366);
  content: '';
}

#contact_us .big_heading {
    color: #fff;
    font-size: 52px;
}

#contact_us .heading p {
  color: #fff;
  font-size: 22px;
}

#contact_us .content {
  position: relative;
  display: block;
  margin-top: 70px;
}

#contact_us .content p {
  color: #ffffffc7;
  font-size: 18px;
}

#contact_us .content a {
  color: #ffffffc7;
}

#contact_us form.card {
  box-shadow: 6px 11px 11px 0px rgb(0 0 0 / 28%);
  border-radius: 8px;
}

#textareacontactpage {
  margin-top: -30px;

}

#application_process {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 80px;
}

#application_process ul {
  position: relative;
  display: inline-block;
  width: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

#application_process ul::after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 35px;
  left: 33px;
  width: 3px;
  background: #03a9f4;
}

#application_process ul li {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 80px;
  min-height: 70px;
  margin-bottom: 35px;
}

#application_process ul li .count {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  padding-top: 13px;
  font-size: 28px;
  font-weight: 800;
  border: 3px solid #2196f3;
  color: #2196f3;
  z-index: 1;
  background: #fff;
}

#application_process ul li .content {
  position: relative;
  display: block;
  width: 100%;
  background: #2196f3;
  padding: 15px;
  padding-left: 60px;
  padding-top: 25px;
  border-radius: 5px;
  text-transform: capitalize;
  top: 0;
  height: 70px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: 2px solid #9bd3ff;
}

#application_process ul li .content::after {
  content: '';
  position: absolute;
  display: block;
  top: 8px;
  left: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #2196f3;
  filter: drop-shadow(7px 0px 7px rgb(0 0 0 / 35%));
  transform: rotate(180deg);
}

#application_process ul li .content .icon {
  position: absolute;
  top: 17px;
  left: 12px;
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(1);
}

#services_tabs {
  position: relative;
  display: block;
  width: 100%;
  background: #d6edff;
  margin-bottom: 70px;
}

#services_tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#services_tabs ul::after {
  content: '';
  display: table;
  clear: both;
}

#services_tabs ul li {
  position: relative;
  display: block;
  width: 20%;
  float: left;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 13px;
  text-transform: capitalize;
  letter-spacing: 1px;
  transition: all 0.2s ease-in;
}

#services_tabs ul li .icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 10px;
  transition: all 0.2s ease-in;
}

#services_tabs ul li:hover {
  background: #2196f3;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
}

#services_tabs ul li:hover .icon {
  filter: invert(1);
  transform: rotateY(360deg);
}

.member_form_wrap {
  padding-right: 55px;
}

.member_form_wrap_bg {
  position: relative;
  display: block;
  width: 100%;
  height: 560px;
  /* background-image: url('../img/43547063_s.jpg') */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.sign_in_form {
  position: relative;
  left: -8rem;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 50px;
  background: #fff;
  padding: 50px 25px;
  border-radius: 8px;
  box-shadow: 4px 10px 20px 1px #00000059;
  margin-bottom: 20px;
}

.sign_in_forms {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 50px;
  background: #fff;
  margin-top: 100px;
  margin-left: 100px;
  padding: 50px 25px;
  border-radius: 8px;
  box-shadow: 4px 10px 20px 1px #00000059;
  margin-bottom: 20px;
}




.sign_in_form .button {
  width: 100%;
  border: 2px solid #dbefff;
  box-shadow: none;
  border-radius: 8px;
}

.sign_in_form .anchor {
  background: #2196f3;
  color: #fff;
  padding: 0px 5px;
  border-radius: 10px;
}

#login_page {
  position: relative;
  overflow: auto;
  display: block;
  width: 100%;
  min-height: 100vh;
  padding-top: 90px;
  background: url('../img/pexels-sergey-torbik-7365442.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  overflow: hidden;
}

#login_page::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, black, #2196f366);
  content: '';
}

.button.gmail_login {
  width: auto;
  text-transform: initial;
  background: #fff;
  color: #000;
  padding-left: 60px;
}

.button.gmail_login::after {
  content: '';
  position: absolute;
  top: 7px;
  left: 15px;
  width: 20px;
  height: 20px;
  background: url('../img/social_icons/gmail.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#over_banner {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background: #f5f5f5;
}

#over_banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: url('../../assets/img/pexels-andrea-piacquadio-3764568.jpg'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#over_banner::after {
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #28587edb;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(1px);
}

#over_banner .location {
  position: relative;
  top: 50vh;
  max-width: 600px;
  z-index: 9;
  display: inline-block;
  transform: translateY(-50%);
  width: 100%;
  border-radius: 12px;
  box-shadow: 4px 6px 10px -3px rgb(0 0 0 / 30%);
  border: 1px solid #e3e3e3;
  padding: 30px 30px;
  background: #fff;
  margin: auto;
  /* left: 10%; */
}


#over_banner .sign_in_form {
  margin-top: 100px;
  margin-bottom: 50px;
}

#over_banner .location .input_group .input {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  padding-left: 15px;
  padding-left: 15px;
  border: 2px solid #2196f3;
}

#over_banner .location h3 {
  font-size: 20px;
  color: #2c2727;
  font-weight: 300;
  margin-bottom: 50px;
}

#over_banner .service {
  list-style: none;
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 40px;
}

#over_banner .service li {
  display: block;
  min-height: 70px;
  padding: 12px;
  cursor: pointer;
  padding-left: 90px;
  text-align: left;
  position: relative;
  margin-bottom: 20px;
  background: #f9f9f9;
  border-radius: 10px;
}

#over_banner .service li .bg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#over_banner .service li .text {
  position: relative;
  display: block;
  width: 100%;
  text-transform: capitalize;
  color: #000;
  font-weight: 400;
  font-size: 13px;
}

#over_banner .service li .title {
  text-transform: capitalize;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

#over_banner .service li:hover .bg {
  outline: 2px solid #2196f3;
  border-color: #2196f3;
}

#book_page {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}

#employees .review {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

#employees .review li {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 25px;
}

#employees .review li::after {
  content: '';
  display: table;
  clear: both;
}

#employees .option li {
  padding: 10px 35px;
  padding-left: 60px;
  height: 42px;
  display: inline-block;
  width: auto;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border: 1px solid #ccc;
  border-radius: 25px;
}

#employees .option li::before {
  display: none;
}

#employees .option li .icon {
  position: absolute;
  top: 4px;
  left: 10px;
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#employees .option li:hover {
  background: #2196f3;
  color: #fff;
}

#employees .option li:hover .icon {
  filter: invert(1);
}

.selectable {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
}

.selectable::after {
  content: '';
  clear: both;
  display: table;
}

.selectable li {
  position: relative;
  width: 50%;
  float: left;
  padding-left: 30px;
  margin-top: 15px;
  text-transform: capitalize;
  color: #333;
  cursor: pointer;
  user-select: none;
}
.selectable input[type="checkbox"] {
  margin-top: 0px;
  height: 12px;
}

.selectable li::after {
  /* content: ''; */
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #acacac;
  border-radius: 2px;
}

.selectable li::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  width: 10px;
  height: 5px;
  z-index: 1;
  border: 2px solid #2196f3;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  display: none;
}

.selectable li[active]::after {
  border-color: #2196f3;
}

.selectable li[active]::before {
  display: block;
}

#employees .input {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  border-radius: 20px;
  min-height: 40px;
  border: 1px solid #ccc;
  padding-left: 15px;
  padding-right: 15px;
}

#employees .input:active,
#employees .input:focus {
  outline: 2px solid #2196f3;
}

#employees .inner {
  margin-top: 15px;
}

#employees .review li .title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 700;
}

#employees .review li .value {
  font-size: 14px;
  color: #585858;
  margin-top: 5px;
  position: relative;
  display: block;
}

#employees .review li .value small {
  display: block;
}

#employees .review li::before {
  content: '';
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  height: 1px;
  background: #ccc;
}

#employees .price {
  float: right;
}

#navigation .drpdwn .drpdwn_menu {
  position: absolute;
  width: auto;
  left: 0;
  top: 100%;
  background: #162b3c;
  padding: 0;
  border-radius: 7px;
  display: none;
}

#navigation .drpdwn:hover .drpdwn_menu {
  display: block;
}

#navigation .drpdwn .drpdwn_menu li {
  float: none;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

#navigation .drpdwn .drpdwn_menu li a {
  color: #ffffff;
}

#navigation .drpdwn .drpdwn_menu li:hover::after {
  display: none;
}

#navigation .drpdwn .drpdwn_menu li:first-child {
  margin-top: 20px;
}

#datepicker {
  width: 100%;
  display: block !important;
}

#datepicker .datepicker-inline,
#datepicker .datepicker-inline table {
  width: 100%;
}

#profile_page {
  position: relative;
  margin-top: 100px;
  width: 100%;
  min-height: calc(100vh - 150px);
}

.sidebar {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 9px 0px;
  top: 60px;
  background: #004f8f;
  text-align: center;
  z-index: 1;
}

.sidebar ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
}

.sidebar ul li {
  position: relative;
  display: block;
  width: auto;
  padding: 12px 0;
  padding-left: 37px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
  color: #e9e9e9;
  margin: 0px 7px;
  float: left;
  padding-right: 15px;
}

.sidebar ul li[active] {
  background-color: #03a9f4;
  border-radius: 6px;
  color: #fff;
  border: 2px solid #88daff;
}

.sidebar ul a:last-child li {
  border-bottom: none;
}

.sidebar ul li .icon {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.9;
}

.sidebar ul li .icon.negative,
.sidebar ul li .icon {
  display: none;
}

.sidebar ul li[active] .icon.negative,
.sidebar ul li .icon {
  display: block;
}

#profile_page .view {
  position: relative;
  display: block;
  width: 100%;
}

#profile_page .content {
  position: relative;
  display: block;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

#profile_page .no_items {
  position: relative;
  display: block;
  text-align: center;
  padding: 25px;
  border-radius: 12px;
}

#profile_page .no_items .icon {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#profile_page .no_items h3 {
  font-size: 20px;
  color: #7e7e7e;
  font-weight: 400;
}

#profile_page .no_items p {
  font-size: 14px;
  color: #666;
}

#profile_page .no_items .button {
  position: relative;
  margin-top: 12px;
}

#profile_page .settings_form {
  position: relative;
  display: inline-block;
  max-width: 500px;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  box-shadow: 6px 6px 11px -6px rgb(0 0 0 / 30%);
}


#change_password_window {
  display: none;
}

#change_password_window[active] {
  display: block;
}

#my_appointments .card {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  margin-top: 0px;
  padding: 15px;
  box-shadow: 3px 7px 8px -2px rgb(0 0 0 / 15%);
  /* height: 170px; */
  transition: all 0.3s ease-in-out;
  border-radius: 12px;
  border: 1px solid #ebebeb;
}

#my_appointments .card .relative_time,
#my_appointments .card .absolute_time,
#my_appointments .card .profile {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 2px;
}

#my_appointments .card .profile {
  margin-top: 15px;
  margin-bottom: 12px;
  min-height: 64px;
}

#my_appointments .card .relative_time h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  color: #6b6b6b;
  margin: 0;
}

#my_appointments .card .absolute_time h4 {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  color: #51535f;
}

#my_appointments .card .relative_time h4 {
  font-size: 12px;
  text-transform: capitalize;
  color: #51535f;
  font-weight: 500;
  padding-top: 6px;
  position: relative;
  padding-left: 18px;
  margin: 0;
}

#my_appointments .card .relative_time .button {
  padding: 5px 30px;
  font-size: 11px;
  color: #fff;
  background: #CDDC39;
  border: 1px solid #b4c12d;
  box-shadow: none;
}

#my_appointments .card .profile .avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #efefef;
}

#my_appointments .card .profile .text {
  padding-left: 72px;
  padding-top: 10px;
  text-align: left;
}

#my_appointments .card .profile .text h3 {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #32333c;
  margin: 0;
}

#my_appointments .card .profile .text p {
  text-transform: capitalize;
  font-size: 11px;
  color: #717171;
}

#my_appointments .card .completed {
  display: block;
  position: relative;
  width: auto;
  font-size: 12px;
  text-transform: capitalize;
  color: #777;
  padding-left: 18px;
  top: 5px;
}

#my_appointments .card .completed::after {
  content: '';
  position: absolute;
  top: 0px;
  left: -1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  /* background-image: url('../img/done.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#my_appointments .card .relative_time h4::after {
  content: '';
  position: absolute;
  top: 6px;
  left: -1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-image: url('../img/clock.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#my_appointments .card .more_details {
  position: relative;
  width: 100%;
  display: block;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#my_appointments .card[active] .more_details {
  opacity: 1;
}

#my_appointments .card[active] {
  display: block;
  height: 380px;
}

#my_appointments .card .more_details .address {
  position: relative;
  display: block;
  width: 100%;
}

#my_appointments .card .more_details .address p {
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
  margin-bottom: 7px;
  padding-left: 20px;
  color: #565656;
}

#my_appointments .card .more_details .address p::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  /* background-image: url('../img/placeholder.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.6;
}

#my_appointments .card .more_details .address .button {
  width: 100%;
  white-space: nowrap;
  font-size: 10px;
  box-shadow: none;
  background: #fff;
  display: inline-block;
  padding: 12px 14px;
  padding-left: 32px;
  color: #848484;
  text-transform: uppercase;
  border: 1px solid #dedede;
}

#my_appointments .card .more_details .host {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  display: block;
  width: 100%;
  min-height: 42px;
}

#my_appointments .card .more_details .host .avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  /* background-image: url('../img/uploads/photo-1570295999919-56ceb5ecca61.jpeg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#my_appointments .card .more_details .host p {
  padding-left: 48px;
  top: 10px;
  position: relative;
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 300;
}

#my_appointments .card .more_details .host p b {
  color: #000;
}

#my_appointments .card .more_details .host p span {
  display: block;
  font-size: 11px;
  text-transform: capitalize;
  color: #737373;
}

#my_appointments .card .more_details .billing {
  margin-top: 10px;
  padding-right: 8px;
}

#my_appointments .card .more_details .billing p {
  font-weight: 500;
  font-size: 16px;
  color: #2b282f;
}

#my_appointments .card .more_details .billing p.paid {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-left: 20px;
}

#my_appointments .card .more_details .billing p.paid::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 14px;
  height: 14px;
  display: block;
  /* background-image: url('../img/done.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#my_appointments .card .more_details .buttons_pane {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 12px;
}

#my_appointments .card .more_details .buttons_pane .button {
  width: calc(50% - 5px);
  float: left;
  margin-right: 5px;
  background: #49aeff;
  font-size: 11px;
  padding: 11px;
  box-shadow: none;
  border: 1px solid #2196f3;
}

#my_appointments .card .ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  padding-top: 50%;
  background: rgb(220 220 220 / 0.5);
  z-index: 1;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  opacity: 0;
}

#my_appointments .card[active] .ripple {
  display: block;
  animation-name: card_ripple;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

@keyframes card_ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}

.profile_heading {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 300;
  color: #6a6a6a;
}

.small_heading h3 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  margin: 0;
}

.appoint_wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
}

.appoint_wrapper .notify {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 6px 10px -6px rgb(0 0 0 / 10%);
  min-height: 142px;
}

.appoint_wrapper .notify .bg {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 10px;
  left: 10px;
  border-radius: 7px;
  /* background-image: url('../img/uploads/pexels-andrea-piacquadio-3764568.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ececec;
}

#profile_page .appoint_wrapper .notify .content {
  padding-left: calc(120px + 15px) !important;
  float: none;
  width: 100%;
}

.appoint_wrapper .notify .content h3 {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  color: #271d42;
}

.appoint_wrapper .notify .content p {
  font-size: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #616161;
}

.appoint_wrapper .notify .content .address {
  font-size: 11px;
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #49425a;
  margin: 0;
  text-align: left;
}

.appoint_wrapper .notify .content .dtime {
  position: relative;
  width: 50%;
  float: right;
  text-align: right;
}

.appoint_wrapper .notify .content .dtime .time {
  font-size: 12px;
  font-weight: 600;
  display: block;
  color: #271d42;
}

.appoint_wrapper .notify .content .dtime .date {
  font-size: 10px;
  color: #737272;
}

.appoint_wrapper .notify .content .intro {
  float: left;
  width: 50%;
  text-align: left;
}

#gift_cards .card .bg {
  position: relative;
  display: block;
  width: auto;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: -8px;
  margin-bottom: 10px;
}

.anchor.primary {
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 15px;
}


#membership_benifits .content {
  text-align: center;
  padding-top: 40px;
}

#membership_benifits .content ul {
  position: relative;
  display: block;
  z-index: 1;
  list-style: none;
  padding: 25px;
  text-align: left;
}

#membership_benifits .content ul li {
  position: relative;
  display: block;
  width: calc(25% - 20px);
  padding-left: 85px;
  float: left;
  margin: 0 10px;
}

#membership_benifits .content ul li .icon {
    position: absolute;
    top: -13px;
    left: 0;
    width: 75px;
    height: 75px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    background-color: #2196f3;
    border-radius: 50%;
    border: 2px solid #fff;
}

#membership_benifits .content ul li .title {
  display: block;
  font-size: 13px;
}

#membership_benifits .content ul li .text {
  font-size: 11px;
}

#membership_benifits .content .button {
  border: 2px solid #86c7fb;
}

#membership_benifits {
  color: #fff;
  background: #162b3c;
}

#book_page {
  background-color: #f7f7f7;
  min-height: 80vh;
}

#progressPills {
  top: 65px;
  position: relative;
  z-index: 3;
}

.sidebar.tabs ul {
  width: 100%;
}

.sidebar.tabs ul li {
  width: calc(16.666% - 10px);
  float: left;
  margin: 0;
  text-align: left;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

#section_group {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
}


.products_scroll_wrapper {
  position: relative;
  display: block;
  width: auto;
  white-space: nowrap;
  overflow: auto;
  margin-right: -15px;
  margin-left: -15px;
}

.products {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 9px;
}

.products::after {
  content: '';
  display: table;
  clear: both;
}

.products .item_wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: normal;
  padding: 10px;
}

.scroll_wrapper .products .item_wrapper {
  width: calc(50% - 15px);
}

.products .item {
  position: relative;
  display: block;
  width: 100%;
  box-shadow: 3px 3px 6px -3px rgb(0 0 0 / 35%);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.products .item .bg {
  position: relative;
  display: block;
  width: 100%;
  height: 130px;
  background: #f9f9f9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.products .item .content {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 10px 5px;
  text-align: center;
}

.products .item .content span.title {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}

.products .item .content span.rate {
  position: relative;
  display: block;
  font-size: 13px;
  display: none;
}

.products .item .content span.rate i {
  font-size: 11px;
  text-decoration: line-through;
  color: #777;
  font-style: normal;
}

.products .item .content span.rate .colored {
  font-size: 11px;
  font-weight: bold;
  color: #1b7ac5;
}

.products .item .content span.rate b {
  position: relative;
  display: block;
  font-size: 13px;
}

.products .item_wrapper:last-child {
  margin: 0;
}

.product_collector {
  margin-top: 0px;
}

.gen_heading {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gen_heading h3 {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.5px;
  word-spacing: 1.5px;
}

.gen_heading p {
  display: inline-block;
  font-size: 11px;
  text-transform: capitalize;
  font-weight: 300;
}

.products .item[selected]::before,
.time_option[active]::before {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  background-color: #2196f3;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: 2;
}

.products .item[selected]::after,
.time_option[active]::after {
  content: '';
  position: absolute;
  top: 18px;
  right: 17px;
  width: 12px;
  height: 6px;
  z-index: 4;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

.time_option[active]::after,
.time_option[active]::before {
  transform: scale(0.8) rotate(-45deg);
}

.time_option[active]::after {
  top: 10px;
  right: 9px;
}

.time_option[active]::before {
  top: 0;
  right: 0;
}

.time_options {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  text-align: center;
}

.time_options li {
  position: relative;
  display: inline-block;
  width: calc(32% - 5px);
  padding: 15px;
  background: #fff;
  box-shadow: 0px 5px 10px -5px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid #efefef;
}

.products .item .content p {
  font-size: 12px;
}

#blog {
    position: relative;
    display: block;
    top: -80px;
    width: 100%;
    min-height: 500px;
    background-color: #d6edff;
    padding-top: 50px;
    padding-bottom: 30px;
}

#navigation .nav_bar .app_buttons {
  display: none;
}

#footer .app_buttons img {
  width: 40%;
  float: left;
}

#footer .app_buttons a:first-child img {
  width: 45%;
  margin-right: 2%;
}


#faq_page {
  position: relative;
  display: block;
  margin: 50px 0px;
  padding: 0;
}

#faq_page .faq .question {
  background: #fff;
  border: 1px solid #58bcea;
  color: var(--primary);
  padding: 7px 0;
  position: relative;
  cursor: pointer;
}

#faq_page .faq .question h3 {
  font-size: 15px;
  color: #2d323e;
  font-weight: 700;
  padding-left: 40px;
  margin: 0;
}

#faq_page .faq .answer p {
  font-size: 14px;
  color: #555;
  font-weight: 400;
  margin-top: 20px;
  display: block;
  line-height: 1.35em;
  margin-bottom: 20px;
}

#faq_page .faq .question::after {
  /* content: '+'; */
  position: absolute;
  top: 12px;
  left: 15px;
  width: 10px;
  height: 10px;
  font-size: 18px;
}



#faq_page .faq .answer {
  padding-left: 40px;
}



/* Home section changes */
#anchors {
  position: relative;
  float: right;
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 0;
  background: 0;
  border: 1px solid #a4b2b9;
  border-radius: 20px;
  padding: 5px 27px;
  margin-right: 10px;
}

#anchors:hover {
  /* display: none !important; */
  background-color: #fff !important;
  color: #000 !important;
}

#videofile {
  position: relative;
  left: 8vh;
  display: flex;

  width: 80%;
}

.heading {
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.headinghome {
  position: relative;
  top: 10rem;
  /* left: -6rem; */
  width: 100%;
  height: 42rem;
  text-align: center;
  justify-content: center;
}

.headinghomes {
  position: relative;
  /* top: 10rem; */
  /* left: -6rem; */
  width: 100%;
  /* height: 42rem; */
  text-align: center;
  justify-content: center;
}


.questions>h3 {
  font-size: 15px;
  color: #2d323e;
  font-weight: 700;
  padding-left: 40px;
  margin: 0;
}


.buttonplus {
  font-size: 20px;
  font-weight: 700;
}

.questions {
  display: flex
}

.buttonplus {
  position: relative;
  text-align: end;
  justify-content: end;
  padding-left: 2vh;
}

#faqitem {
  font-size: 17px;
  padding-left: 8px;
  padding-top: 7px;
  font-weight: 700;
}

#faq_page {
  position: relative;
    display: block;
    margin: 27px 0px;
    width: 95%;
    margin-left: 2rem;
    box-shadow: 2px 2px 2px lightblue;
}

#faq_page .faq .question {
  position: relative;
    padding-left: 12px;
    background: #ffffff;
    padding: 7px 0px;
    border: 1px solid #58bcea;
    color: var(--primary);
    border-bottom: 1px solid #58bcea;
}

img .img-responsive {
  border-radius: 12px;
  top: 10rem;
  height: 50vh;
  padding-left: 2vh;
  margin-top: 8rem;
  width: 100%;
}


/* services */
#servicecart:hover {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
  /* border: 1px solid #f8f8f8; */
  /* padding-bottom: 15px; */
  transition: all 0.2.5s ease-in-out;


}

#banner .head h1 {
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  font-weight: 700;
  font-size: 34px !important;
}

.heading h3 {
  font-size: 22px;
  /* text-transform: capitalize;
   */
   text-transform: none !important;
  font-weight: 800;
  color: #0f1538;
  text-align: center;
  justify-content: center;
  padding-left: 1.3vh !important;
}

/* #banner .head h1 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 35px;
  color: #fff;
  font-weight: 650;
  padding-top: 3rem;
}




#banner .head h3 {
  position: relative;
  display: block;                      
  width: 100%;
  font-weight: 600;
  font-size: 15px !important;
  color: #fff;
  font-weight: 400 !important;
  line-height: 1.4em;
  letter-spacing: 0.3px;
  word-spacing: 1px;
} */

#banner .head .button {
  margin-top: -32px;
  padding-bottom: 12px;
  padding-top: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  margin-left: 5px;
  width: 150px;
}

.item {
  /* height: 100%; */
  /* overflow: scroll; */

}

.item .content {
  padding-bottom: 20px;
  /* add some padding to the bottom */
}

.book-button {
  margin-top: 10px;
  /* add some margin to the top */
}

#bannerservices {
  justify-content: left;
}

#memershipbutton {
  position: relative;
  margin: auto;
  text-align: center;
  left: 40% !important;
}



.Read_More {
  position: relative;
  top: 0rem;
  left: 0rem;
  border: none;
  background-color: #ffff;
}


.Read_Mores {
  position: relative;
  top: 0rem;
  left: 0rem;
  border: none;
  background-color: #f2f9ff;

}


#memershipbutton {
  position: relative;
  top: -2rem !important;
}

.toggleimage {
  display: none;
}

#messagebannerdown {
  text-align: center;
  justify-content: space-around;
  margin-top: 6.5rem;
  margin-left: 6.2rem;
  top: 15px;
}

#member_cta .container-member .heading {
  position: relative;
  top: 100px;

}

#becommembersyogatext {
  position: relative;
  text-align: center;
  left: -35px;
  top: 15px;
}

#becommembersyogatext2 {
  position: relative;
  text-align: center;
  left: -47px;
  top: 15px;
}


#becommembersyogatext3 {
  position: relative;
  text-align: center;
  left: -60px;
  top: 15px;
}

#becommembersyogatext4 {
  position: relative;
  text-align: center;
  left: -85px;
  top: 15px;
}

.modal-backdrop.show {
  opacity: 0;
}

.blogbuttons {
  text-align: center;
  justify-content: center;
  position: relative;
  /* right: 25rem; */
  top: -6rem;
}

.react-calendar {
  width: 100% !important;
  padding: 20px;
  border: solid 1px #85e1fd !important;
}


.backbutton {
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  font-weight: 700;
}

#gift_card .heading {
  padding-top: 15px;
}

/* 
.details_blog #types{
  position: relative;
  background-color: #ffff;
  
} */



/* //Become a member */

#services_tab {
  width: 100%;
  background: #d6edff;
}

#services_tab .container-brief {
  justify-content: space-around;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#services_tab .container-brief .selects {
  display: block;
  flex-basis: 18%;


}

.selects .item {
  display: block;
}

.selects .item img {
  width: 40px;
  margin-top: 25px;
  margin-bottom: 10px;
}

#services_tab .container-brief .selects:hover {
  background-color: #03a9f4;
  color: #ffff;
  border-radius: 20px;
  transition: .3s ease-in;

}


.selects .item:hover .icons {
  filter: brightness(0) invert(1);
}

/* //Guest_login */

#over_banner .sign_in_form {
  margin-top: 100px;
  margin-bottom: 50px;
  margin-left: 10rem;
  width: 65%;
  height: 82%;
}

#gmaillogin {
  justify-content: center;
  text-align: center;
  /* margin-left: 13.5rem; */
  margin-top: 10px;
  /* width: 40%; */
  margin: auto;
}





.sign_in_form .button {
  margin-bottom: 12px;
}

.gmail_login {
  margin-top: 10px;
}

.anchor {
  border: none !important;
  text-decoration: none;
}

.input_near_me {
  display: flex;
  justify-content: space-between;
}

.near_me_button {
  /* background-color: red; */
  position: absolute;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  padding: 2px 2px;
  height: 10px;
  cursor: pointer;
}

.near_me_button h3 {

  font-size: 16px;
  padding-top: 12px;
  padding-left: 10px;
  color: blueviolet;
  text-shadow: #000000;
}

.review_page .input_near_me input[type="text"] {
  width: 100%;
  padding: 10px;
  padding-left: 80px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
}
#blog a {
  text-decoration: none;
}

#blog h3 {
  color: var(--primary);
}


#blog .item {background: white;box-shadow: 2px 2px 2px lightblue;padding: 10px;border: solid 1px lightblue;}

#blog .item:hover {
  translate: 0 -10px;
  transition: 0.1s ease-in;
  box-shadow: 2px 12px 2px lightblue;
}
#blog .content {
  padding: 10px;
}
.blogPage {
  padding: 50px 100px;
  background-color: aliceblue;
  width: 100% !important;
  box-sizing: border-box;
}

.blogPost {
  background: white;
  box-sizing: border-box;
  contain: content;
  border-radius: 10px;
  box-shadow: 3px 3px 3px lightblue;
  border: solid 1px #5dcbef;
}

.blog_wrapper {
  padding: 15px;
}
#talent h3 b {
  color: black;
}
.userImg {
  width: 80px;
  height: 80px;
  background-color: #040000;
  border-radius: 50%;
}
.userRatings {
  padding: 50px 10px !important;
}
.userRatings .inner {
  width: 80%;
}
.sideImg img {
  width: 90%;
  margin: auto;
  border-radius: 10px;
  margin-top: 12px;
}

.sideContent h3 {
  font-size: 14px;
  margin-top:14px;
  padding: 0 5px;
}

.sideContent p {
  font-size: 12px;
  width: 95%;
  padding: 0 5px;
}

.sideBox {background: white;border: solid 1px #cce9ff;width: 98%;margin: 10px;box-shadow: 2px 2px 2px lightblue;}

.sideImg {
  padding: 10px;
}

.sideContent {
  padding: 10px !important;
}

.sideBlogs {
  margin-left: 10px;
  background: white;
  padding: 12px;
}

.sideBox:hover {
  translate: -5px -5px;
  transition: .2s ease-in;
  box-shadow: 5px 5px 5px lightblue;
}
.smallHeading {
  font-size: 20px;
  color: var(--primary);
}
.smallHeading {
  font-size: 20px;
  color: var(--primary);
}

.timeline {
  color: var(--primary);
  /* cursor: pointer; */
  
}

.timeline_month {
  display: none;
  transition:1s ease-in;
}

.timeline_year li:hover .timeline_month {
  
  display: block;
  cursor: pointer;
}
.marketplace .button{
  background-color: var(--primary) !important;
  color: white !important;
}
.copyright {width: 50%;}
#types .item {
  background: #fff;
  border: 1px solid #add8e6;
  box-shadow: 2px 2px 2px #add8e6;
  padding: 10px;
}

#types .item:hover {
      box-shadow: 2px 12px 2px #add8e6;
  transition: .1s ease-in;
  translate: 0 -10px;
}
.processImg img {
  width: 100%;
  padding: 10px;
}
.process {
  padding: 50px 0;
}
.provider-process{
  background-color: #162b3c;
}
/* .full_wrapper::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  background-image: url('../img/pexels-andrea-piacquadio-3764568.jpg');
  background-attachment: fixed;
} */
.full_wrapper {
  background-color: #212529c4;
  background-image: url('../img/pexels-andrea-piacquadio-3764568.jpg');
  height: 100%;
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
}
#contact_us .heading h3 {
  color: white;
}
.react-calendar {
  background: none !important;
  border: none !important;
}

.react-calendar__navigation {
  background: var(--primary);
}

.react-calendar__navigation * {
  color: white;
}
.hspace {
  height: 50px;
  width: 100%;
  background: #cae7ff;
  margin: auto;
}
.eye_button {
  background: none;
  border: none;
  bottom: 0px;
  height: 30px;
  left: 90%;
  position: absolute;
  width: 30px;
  z-index: 1;
}

.eye_button img {
  width: 100%;
}
.sign_in_form p {
  text-align: center;
}
/* .sign_in_forms .eye_button {
  left: 44% !important;
} */
.sideBlogs a {
  font-size: 12px;
  text-decoration: none;
  text-align: right;
  position: relative;
  right: 10px;
}
.useLocation {
  text-align: left;
  margin: 5px;
  font-size: 12px;
  color: grey;
}

.useLocation img {
  filter: opacity(0.5);
  margin-top: -2px;
}
#small_banners {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.sign_in_forms a {
  color: var(--primary);
}
.sign_in_forms a:hover {
  color: blue;
}
.new_book {
  background: url('../img/pexels-sergey-torbik-7365442.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  overflow: hidden;
  padding: 20px 0;
  -webkit-backdrop-filter: blur(1);
  backdrop-filter: blur(1);
}
/* .new_book::before{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, black, #2196f366);
  width: 100%;
  height: 100%;
  content: '';
} */
.cta{
  background-color: var(--primary) !important;
  color: white !important;
}

