.preview_admin {
    width: 100%;
    height: 20vh;
    overflow: hidden; /* Ensure the image doesn't overflow its container */
  }
  
  .preview_admin img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container without stretching */
  }
  .reviewTab div {
    justify-content: center;
}