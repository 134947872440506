.sign_in_form{
    justify-content: center;
    text-align: center;
    position: relative;
    margin-left: 45%;
    height:auto;
    margin-bottom: auto;

}
#signtexxt{
    text-decoration: none;
    justify-content: center;
    text-align: center;
}
@media (max-width: 992px){
    #navigation .toggle_button{
        display: block !important;
    }
}
 
