.payments-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto !important;
}

.payments-table th,
.payments-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.payments-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.payments-table button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.payments-table button:hover {
  background-color: #0056b3;
}

.block-td {
  display: block;
}

.block-td span {
  display: block;
}
.provDet {
  padding: 15px;
}

.provDet p {
  margin: 0;
}
.sub {
  font-size: 12px;
}
.cursor2{
  cursor: pointer;
}
.center{
  text-align: center;
}
.vspace70{
  height: 70px;
}





/* release payment */


.payment-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 41%;
}

.payment-form-container .payment-form {
  display: flex;
  flex-direction: column;
}

.payment-form-container .form-group {
  margin-bottom: 10px;
}

.payment-form-container label {
  font-weight: bold;
}

.payment-form-container input[type="text"],
.payment-form-container input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.payment-form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-form-container button:hover {
  background-color: #0056b3;
}