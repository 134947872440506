/* Responsive styles for tablets and mobile devices */
@media (max-width: 1024px) {

    #banner_page {
        height: 500px;
        width: 100% !important;

    }

    #banner_page .head {
        width: 80%;


    }

    #banner_page .head h1 {
        font-size: 1.75rem;

    }

    #banner_page .head h3 {
        font-size: 0.75rem;

    }

    #banner_page .head .button {
        margin-top: 1.5rem;

        padding: 0.5rem 0.75rem;

    }

    #banner_page .arrow_down {
        width: 24px;
        height: 24px;
        bottom: 1.5rem;

    }

    /* // service page banner */
    #banner_page #bannerservices {
        margin: auto;

        backdrop-filter: blur(0) !important;
        background: #ffffffa3 !important;
    }

    #banner_page #bannerservices h1 {
        font-size: 20px;

    }

    #banner_page #bannerservices h3 {
        font-size: 15px;

    }

    #banner_page #bannerservices p {

        font-weight: 700 !important;

    }

    /* // card design */
    #types_card .card_item_wrapper {
        min-width: calc(50% - 15px);

    }

    #types_card .button.small {
        font-size: 10px !important;
        padding: 1em 2em !important;
        font-size: 7px !important;
    }

    #types_card .content p {
        font-size: 11px !important;
    }

    #types_card .card_item_wrapper {
        /* padding: 2px; */
        padding: 8px;
    }

    .booking-card {
        /* flex: 1 1 calc(50% - 10px); */
        width: 48%;
        margin: auto;

    }
}

@media (max-width: 825px) {
    .booking-card {
        /* width: 45%; */
    }

}

@media (max-width: 982px) {

    .booking-modal-container {
        /* background: url(http://localhost:3001/static/media/9496304.232c185….png); */
        background-size: 100% 100%;
        background-attachment: fixed;
        width: 100% !important;
        margin: auto;
        position: absolute;
        top: 100px;
        right: 0;
        left: 0 !important;
    }

    .booking-card-text h3 {
        font-size: 12px;
    }

    .booking-card-addons h3 {
        margin-bottom: 10px;
        font-size: 12px;
    }

    #profile_page .settings_form {
        display: block;
        /* max-width: 500px; */
        width: 100%;
        margin: auto;
        /* text-align: left; */
        /* border-radius: 10px; */
        /* margin-top: 10px !important; */
        margin-bottom: 80px !important;
        /* box-shadow: 0px 8px 18px -6px rgb(0 0 0 / 30%); */
        /* padding: 20px; */
    }
    .userRatings{
        margin: auto !important;
    }
}

@media (max-width: 768px) {

    #banner_page .head {
        width: 90%;
        margin-top: 100px;
        padding-top: 100px;

        /* Full width with small margin */
    }

    #banner_page .head h1 {
        font-size: 28px;
        /* Smaller font size */
    }

    #banner_page .head h3 {
        font-size: 14px;
        font-weight: 300 !important;
        /* Smaller font size */
    }

    #banner_page .head .button {
        margin-top: 1rem;
        /* Adjust margin */
        padding: 0.5rem 0.75rem;
        /* Adjust padding */
    }

    #banner_page .arrow_down {
        width: 20px;
        /* Smaller arrow */
        height: 20px;
        /* Smaller arrow */
        bottom: 1rem;
        /* Adjust bottom position */
    }


    /* // service page banner */
    #banner_page #bannerservices {
        margin: auto;


    }

    #banner_page #bannerservices h1 {
        font-size: 18px;
        /* Smaller font size */
    }

    #banner_page #bannerservices h3 {
        font-size: 14px;
        /* Smaller font size */
    }

    /* // card resposve */
    #types_card .card_item_wrapper {
        min-width: calc(100% - 15px);
        /* 1 card per row */
    }

    #types_card .button.small {
        font-size: 10px !important;
        padding: 1em 2em !important;
        font-size: 7px !important;
    }

    /* //memberhsip section */
    .memberhsip-image-card {
        width: 350px !important;
        height: 350px !important;
        background-size: cover;
        background-position: center;
        border-radius: 7px;
    }

    /* // profile section  */


    .booking-card {
        flex: 1 1 100% !important;

    }

    .gift-card-banner-add {
        position: absolute;
        top: 50px;
        left: 14px;
        width: 95%;
    }
}

@media (max-width: 580px) {



    #banner_page .head h1 {
        /* font-size: 1.25rem; */
        font-size: 18px;
        font-weight: 800;

    }

    #banner_page .head h3 {
        font-size: 18px;
        /* font-weight: 300; */
        line-height: 1.25em;

    }

    #banner_page .head p {
        font-size: 12px;

    }


    #banner_page .head .button {
        margin-top: 0.1rem;

        padding: 0.4rem;

        font-size: 8px;
    }

    #banner_page .arrow_down {
        width: 16px;
        /* Smaller arrow */
        height: 16px;
        /* Smaller arrow */
        bottom: 0.5rem;
        /* Adjust bottom position */
        display: none;
    }

    .banner_sub_section {
        margin-bottom: 100px;
    }

    #banner_page #bannerservices {
        margin: auto;
        padding: 20px 15px;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center content vertically */
    }

    #banner_page #bannerservices h1 {
        font-size: 16px;
        /* Smaller font size */
    }

    #banner_page #bannerservices h3 {
        font-size: 12px;
        /* Smaller font size */
    }

    /* //card design */
    #types_card .card_item_wrapper {
        min-width: calc(100% - 10px);
        /* 1 card per row with reduced spacing */
    }



    #types_card .bg {
        height: 150px;
        /* Adjust height for very small screens */
    }

    #types_card .content {
        padding: 5px;
        /* Adjust padding for very small screens */
    }

    #types_card .content h3 {
        font-size: 1rem;
        /* Smaller font size */
    }

    #types_card .content p {
        font-size: 0.875rem;
        /* Smaller font size */
    }

    #types_card .button.small {
        font-size: 10px !important;
        padding: 1em 2em !important;
        font-size: 7px !important;
    }

    /* //memberhsip section */
    .memberhsip-image-card {
        width: 300px !important;
        height: 300px !important;
        background-size: cover;
        background-position: center;
        border-radius: 7px;
    }

    .title-card {
        margin: 0 0 10px;
        font-size: 18px !important;
        text-transform: capitalize;
        font-weight: 700;
        color: #263d4a;
    }

    .description-card {
        margin: 0 0 20px;
        font-size: 13px !important;
    }

    .FAQ_container {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .faq_page_wrapper {
        width: 100% !important;
        padding: 2% 0;
        margin: auto;
    }

    #faq_image {
        width: 100% !important;
        z-index: 1;
        margin: auto;
    }

    .provider_background {

        background: #d6edff;
        /* display: flex; */
        padding: 10px !important;

    }


}

@media (max-width: 480px) {

    /* // about section card */
    .image-card {
        width: 95% !important;
        margin: 10px !important;

    }

    .service-image-card {
        width: 95% !important;
        margin: 10px !important;
        height: 250px !important;
    }

    .content-right li {
        width: 100% !important;
        margin: auto !important;
        text-align: left;
    }



    .about-image-card {
        width: 95% !important;
        margin: auto !important;
    }

    #banner_page .head {
        width: 100%;
        margin-top: 100px;
        padding-top: 100px;
        /* justify-content: center !important; */
        /* text-align: center !important; */
    }

    #banner_page .head h1 {
        /* font-size: 1.25rem; */
        font-size: 20px;
        /* font-weight: 700; */

    }

    #banner_page .head h3 {
        font-size: 16px;
        /* font-weight: 300; */
        line-height: 1.25em;

    }

    #banner_page .head p {
        font-size: 12px;

    }


    #banner_page .head .button {
        margin-top: 0.1rem;

        padding: 0.4rem;

        font-size: 8px;
    }

    #banner_page .arrow_down {
        width: 16px;
        /* Smaller arrow */
        height: 16px;
        /* Smaller arrow */
        bottom: 0.5rem;
        /* Adjust bottom position */
        display: none;
    }

    .banner_sub_section {
        margin-bottom: 100px;
    }

    #banner_page #bannerservices {
        margin: auto;
        padding: 20px 15px;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center content vertically */
    }

    #banner_page #bannerservices h1 {
        font-size: 16px;
        /* Smaller font size */
    }

    #banner_page #bannerservices h3 {
        font-size: 12px;
        /* Smaller font size */
    }

    /* //card design */
    #types_card .item_wrapper {
        min-width: calc(100% - 10px);
        /* 1 card per row with reduced spacing */
    }





    #types_card .bg {
        height: 150px;
        /* Adjust height for very small screens */
    }

    #types_card .content {
        padding: 5px;
        /* Adjust padding for very small screens */
    }

    #types_card .content h3 {
        font-size: 1rem;
        /* Smaller font size */
    }

    #types_card .content p {
        font-size: 0.875rem;
        /* Smaller font size */
    }

    #types_card .button.small {
        font-size: 10px !important;
        padding: 1em 2em !important;
        font-size: 7px !important;
    }

    #downloadimage {
        width: 146px !important;
        padding: 8px;
    }


}


@media (max-width: 375px) {



    #banner_page .head p {
        font-size: 12px;

    }

    .button.primary {
        padding: 8px 10px !important;
        border-radius: 25px;
        box-shadow: 0px 5px 10px rgb(0 0 0 / 25%);
        font-size: 8px;
        letter-spacing: 2px;
        word-spacing: 3px;
        background: linear-gradient(45deg, #2196f3, #00bcd4);
        border: none;
        margin: 0 10px;
    }

    .button.ghost {
        background: #f5f5f5;
        color: #000;
        padding: 8px 10px !important;
        font-size: 8px;
        letter-spacing: 2px;
        word-spacing: 3px;
        margin: 0 10px;

    }

    .time_options li {
        font-size: 10px !important;
    }
}


@media (max-width: 325px) {

    .time_options li {
        font-size: 9px !important;
    }
}


@media (max-width: 295px) {

    .time_options li {
        font-size: 8px !important;
    }
}