/* //HOme page banner css */

#banner_page {
  position: relative;
  display: block;
  width: 100%;
  height: 600px !important;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url('../img/masseur-doing-massage-backbone-man-body-spa-salon-beauty-treatment-concept.jpg'); */
  background-size: cover;
}

#banner_page::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black -13%, #00000033);
}


.banner_sub_container::after {
  background: none !important;

}



#banner_page .head {
  position: absolute;
  display: block;
  width: 50%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: left;

}

#banner_page .head h1 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  /* white-space: nowrap; */

}

#banner_page .head h1 span {
  /* display: block; */
  color: #2196f3;
  /* display: inline-block; */

}

#banner_page .head h3 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  /* line-height: 1.4em; */
  letter-spacing: 0.3px;
  word-spacing: 1px;
  line-height: 1.5em;
}




#banner_page .head .button {
  margin-top: 35px;
  padding-bottom: 12px;
  padding-top: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  margin-left: 5px;
}

.button.hollow {
  background: none;
  border: 2px solid #fff;
}

#banner_page .button.primary {
  background: #2196f3;
  border: 2px solid #00e2ff;
}

#banner_page .arrow_down {
  position: absolute;
  bottom: 32px;
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  background: url('../img/arrow_down.svg');
  background-position: 7.5px 9px;
  background-repeat: no-repeat;
  background-size: 45%;
  cursor: pointer;
  z-index: 2;
}

#banner_page .arrow_down::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: calc(50% - 1px);
  width: 1px;
  height: 50px;
  background: linear-gradient(45deg, white, transparent);
}

/* // content header section */

.Header_container{
  width: 100%;
  margin: auto;
  margin-top: 40px;
}


/* Default Card Styles */

/* Base Styles */
#types_card .marketplace {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adds space between the cards */
}

#types_card .card_item_wrapper {
  flex: 1 1 calc(33.333% - 20px); /* Allows 3 cards per row, adjusting for the gap */
  box-sizing: border-box;
}

#types_card .card_item {
  background: #fff;
  border: 1px solid #add8e6;
  box-shadow: 2px 2px 2px #add8e6;
  border-radius: 7px;
  padding: 10px;
  margin: 10px 0;
}

#types_card .card_item_wrapper .bg {
  position: relative;
  display: block;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  padding-top: 65%;
}

#types_card .card_item_wrapper .content {
  position: relative;
  display: block;
  width: 100%;
  min-height: 23px;
  margin: 10px;
}

#types_card .card_item_wrapper.first .bg {
  min-height: 350px;
  margin-top: -80px;
}

#types_card .card_item_wrapper.second .bg {
  min-height: 250px;
}

#types_card .card_item_wrapper.third .bg {
  min-height: 350px;
  margin-top: 0px;
}

#types_card .button {
  position: relative;
  float: right;
  margin-right: 20px;
  text-transform: uppercase;
  padding-top: 11px;
  padding-bottom: 8px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 9px;
  color: #000;
  background: #fff;
  border: 1px dashed #afafaf;
  box-shadow: none;
  bottom: 0;
}

#types_card .button:hover {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
  box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 0.2);
}

#types_card .content h3 {
  font-size: 18px;
  font-weight: 800;
}

#types_card .content p {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
  word-spacing: 3px;
  color: #616161;
  margin-bottom: 20px;
}

#types_card .card_item:hover {
  box-shadow: 2px 12px 2px #add8e6;
  transition: .1s ease-in;
  translate: 0 -10px;
}

.card-slider {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.Read_More{
  cursor: pointer;
  font-size: 14px;
  opacity: 0.7;
}
/* Media Queries for Responsiveness */






/* // service banner page */

#banner_page #bannerservices {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #ebebebbd;
  height: 500px;
  width: 600px;
  padding: 69px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  color: var(--bs-body-color);
  margin: 0 10px 0 300px;
  position: relative;
  top: 230px;


}



#banner_page #bannerservices h1 {
  font-size: 24px;
  font-weight: 800;
  padding-top: 15px;
  color: var(--bs-body-color);
  white-space: wrap;
  /* color: #000000; */


}

#banner_page #bannerservices h3 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
  color: var(--bs-body-color);
  margin-top: 0;
  margin-bottom: 1rem;
  /* color: #000; */
}




/* //faq_image */
/* faq css */
.FAQ_container {
	display: flex ;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between !important;
}

.faqbutton {
	text-align: center;
	/* display: flex;
  margin-left: 30px; */
	/* justify-content: center; */
	/* Horizontally center-aligns the content */
	/* align-items: center; */
	/* Vertically center-aligns the content */
}

/* .faqbutton button {
	
} */

.faq_page_wrapper {
	width: 70%;
	padding: 2% 0;
}

#faq_image {
	width: 30%;
	z-index:1;
}

#faq_image img {
	width: 90%;
	/* height: 60%; */
	border-radius:50%;
}


.gift-card-banner-add{
  position: absolute;
  top: 50px;
}

/* // about section or two content section */
