:root {
	--theme: #03a9f4;
}

.container__view {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	margin-bottom: 5%;
	width: 100%;
}


.notification__view {
	margin: 20px;
	justify-self: center;
	width: 90%;
}

.notification__item {
	width: 100%;
	height: auto;
	border-radius: 10px;
	margin: 20px auto;
	padding: 20px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.notification__titleView {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.notification__titleView>h3 {
	font-size: 20px;
}

.notification__titleView>h4 {
	font-size: 18px;
}

.notification__item>p {
	color: lightslategray;
}

.support__input {
	width: 80%;
	outline: 0;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	padding: 10px !important;
}

.supportSection {
	text-align: center;
}

.container__view>h3 {
	font-size: 20px;
	margin: 20px 0;
}

.support__button {
	padding: 10px 50px;
	border: 0;
	outline: 0;
	border-radius: 10px;
	cursor: pointer;
	background: var(--theme);
	color: #fff;
	text-transform: uppercase;
}

.Toastify__progress-bar {
	background: var(--theme);
}

.Toastify__toast-body svg {
	fill: var(--theme);
	/* Change the success icon color here */
}

.favorite__item {
	width: 30%;
	height: 400px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* border: 1px solid grey; */
	border-radius: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.favorite__itemImage {
	width: 100%;
	height: 40%;
}

.favorite__itemRating {
	display: flex;
	/* align-items: center; */
}

.favorite__itemRating>p {
	color: var(--theme);
	margin-left: 2px;
}



/* //review page detad */
.modal-wrapper-review {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	/* background: rgba(0, 0, 0, 0.5); */
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	/* background: url(../../../assets/img/9496304.png); */
	/* background-image: url("../../../assets/img/bg11.jpg"); */


	.modal-content-review-user {
		background: #fff;
		padding: 20px;
		border-radius: 8px;
		width: 40%;
		max-width: 90%;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		text-align: center;
		position: relative;
	}

	.modal-content-review-user .close {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 1.5rem;
		cursor: pointer;
	}

	.modal-content-review-user h3 {
		margin-bottom: 20px;
	}

	.modal-content-review-user textarea {
		width: 100%;
		height: 100px;
		margin-bottom: 20px;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	.modal-content-review-user button {
		padding: 10px 20px;
		background-color: #007bff;
		color: #fff;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-size: 1rem;
	}

	.modal-content-review-user button:disabled {
		background-color: #ccc;
	}
}

@media (max-width: 982px) {
	.modal-content-review-user {
		width: 90%;
	}
	.notification__item{
		zoom: 0.7;
	}
	.supportSection {
		text-align: center;
		font-size: 12px;
	}
	.supportSection h3 {
		text-align: center;
		font-size: 16px;
	}
	.container__view h4{
		text-align: center;
		font-size: 12px;
		padding: 5px;
	}
	
	.support__button {
		position: relative;
        top: -38px;
		padding: 10px 50px;
		margin-bottom: 40px;
		border: 0;
		outline: 0;
		border-radius: 10px;
		cursor: pointer;
		background: var(--theme);
		color: #fff;
		text-transform: uppercase;
	}
	
}