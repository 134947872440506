/* Listprovider.css */

.provider-list-container {
    width: 100%;
    margin: 50px auto;
}

/* Style for the search bar */

.search_bar_Listprovider {
    width: 80%;
    margin: auto;

}

.search_bar_Listprovider input {
    width: 100%;
    padding: 10px;
    margin: 60px 0px;
    box-sizing: border-box;
    border-radius: 20px;
    /* box-shadow: 2px 2px 2px 2px #aaa; */
    border: 2px solid #aaa;

}

.Provider_List {
    width: 80%;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 30px;
}

.provider_card {
    width: 24%;
    margin: 10px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px 2px #aaa;
    padding: 10px;
    border-radius: 20px;
}

.provider_card img {
    width: 100%;
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Maintain aspect ratio and cover the entire container */
    border-radius: 20px;
}

.Provider_List .content {
    display: flex;
    justify-content: space-between;



}

.Provider_List .content p {
    color: #2196f3;
    margin: 20px 0;
}

.Provider_List .content h3 {

    font-size: 24px;
    margin: 20px 0;
    color: #03a9f4;
}


.Provider_List .decription {
    font-size: 12px;
    margin-top: -10px;
    color: #6c757d;
}

.Listprovider_button .button {
    margin-bottom: -5px !important;
    font-size: 10px;
}

.Listprovider_button{
    text-align: center;
}
/* Responsive CSS */
@media (max-width: 768px) {
    .provider_card {
        width: calc(100% - 10px);
        margin-bottom: 10px;
        /* Two items per row on smaller screens */
    }
}