.sidebar_tabs {
	/* position: sticky; */
	display: block;
	width: 100%;
	height: 10%;
	padding: 19px 0px;
	top: 60px;
	background: #004f8f91;
	text-align: center;

}
.price_tag{
	position: absolute;
	bottom: 0;
	left: 10px;
}

.sidebar_tabs ul {
	/* padding: 0; */
	list-style: none;
	/* margin: 0; */
	display: inline-block;
	width: 80%;
	margin: auto;
	justify-content: center;
	text-align: center;
}

.sidebar_tabs ul li {
	width: calc(16.666% - 10px);
	float: left;
	margin: 0;
	text-align: left;
	margin: 0 5px;
	cursor: pointer;
	border: 2px solid transparent;
	color: #fff;
	justify-content: center;
	text-align: center;
}

.sidebar_tabs ul li[active] {
	background-color: #03a9f4;
	border-radius: 6px;
	color: #fff;
	border: 2px solid #88daff;
	width: 40%;
	justify-content: center;
	text-align: center;
}

.sidebar_tabs ul li:hover {
	background-color: #03a9f4;
	border-radius: 6px;
	/* padding: 10px 10px; */
	color: #fff;
	border: 2px solid #88daff;
	justify-content: center;
	text-align: center;
	/* height: 40px; */
	transition: 0.3s ease-in-out;
}

.sidebar_tabs ul li .icon {
	position: absolute;
	top: 50%;
	left: 12px;
	width: 16px;
	height: 16px;
	transform: translateY(-50%);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 0.9;
}

.sidebar_tabs ul li .icon {
	position: absolute;
	top: 50%;
	left: 12px;
	width: 16px;
	height: 16px;
	transform: translateY(-50%);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 0.9;
}

.section {
	width: 60%;
	margin: auto;
	margin-top: 100px;
	/* margin-bottom: 200px; */
	background-color: #ffffffe3;
	padding: 80px;
	border-radius: 10px;
	box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
}

.smallOne {
	width: 30%;
	padding: 25px;
}

#employees {
	justify-content: center;
	text-align: center;
	width: 100% !important;
	padding: 40px;
}

#employees label {
	justify-content: center;
	text-align: center;
	font-size: 18px !important;
	font-weight: 600;
}

#employees .review {
	position: relative;
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
	justify-content: center;
	text-align: justify;
}

#employees .option li {
	/* padding: 10px 50px; */
	cursor: pointer;
	transition: all 0.2s ease-in;
	border: 1px solid var(--primary);
	border-radius: 25px;
	margin-top: 20px;
	/* margin-left: 15px; */
	background: white;
}

#sec_wiz_2,
.sec_wiz_2 {
	width: 70%;
	margin: auto;
	margin-top: 30px;
	height: auto;
	/* margin-bottom: 30px; */
	background: #ffffffe3;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
}

.products .item_wrapper {
	position: relative;
	display: inline-block;
	width: 33%;
	margin: auto;
	white-space: normal;
	padding: 10px;
}

.progressbar_userpannel {
	position: relative;
	/* top: 10px; */
	width: 95%;
	/* margin: auto; */
}

.progressbar_userpannel .progressbarproviders {
	position: relative;
	left: 0px;
	width: 70%;
	margin: auto;
	background: white;
	border: solid 1px lightblue;
}

.progressbar_userpannel .button {
	padding: 10px !important;
	margin-top: 10px;
	opacity: 0.9;
}

/* //Location */
#over_banners {
	width: 100%;
	margin: auto;
	margin-top: 140px;
}

#over_banners .location-container {
	width: 40%;
	margin: auto;
}

/* #over_banners .location-container .locations{
    width: 40%;
    margin: auto;
} */

.gender.selected,
.time_option.selected {
	background-color: #03a9f4;
	/* Set the background color to blue for selected items */
	color: white;
	/* Set the text color to white for better visibility */
}

.selected {
	background-color: #03a9f4 !important;
	color: #fff;
	border-color: #3498db !important;
}

.progress-bar {
	background-color: var(--primary);
}

/* Add these styles to your CSS file */

.coupon-section {
	margin-top: 20px;
	display: flex;
}

.coupon-section input {
	width: 200px;
	padding: 8px;
	border: 1px solid #ccc;
}

.apply-coupon-button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 8px 12px;
	cursor: pointer;
}

.apply-coupon-button:hover {
	background-color: #0056b3;
}

/* //Firtform */

.review_page {
	width: 50% !important;
	margin: auto;
	margin-top: 0 !important;
	/* margin-bottom: 200px; */
	background-color: #ffffffe3;
	/* padding: 80px; */
	border-radius: 10px;
	box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	padding: 10px;
	position: relative;
	z-index: 1;
}

.review_page .form-group {
	margin-bottom: 5px;
}

.review_page label {
	display: block;
	margin-bottom: 5px;
}

.review_page input[type="text"],
.review_page input[type="email"],
.review_page input[type="password"],
.review_page textarea,
.review_page input[type="number"] {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.review_page1 input[type="text"],
.review_page1 input[type="email"],
.review_page1 input[type="password"],
.review_page1 textarea,
.review_page1 input[type="number"] {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 14px;
}

.selectable label {
	text-transform: capitalize !important;
}

.review_page1 {
	width: 60% !important;
	margin: auto;
	margin-top: 20px !important;
	margin-bottom: 10px;
	background-color: #fff;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
}

.review_page1 .eye_button {
	top: -35px;
}

.half {
	width: 48%;
}

.review_page button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}

.review_page button:hover {
	background-color: #0056b3;
}

/* // second for */

.second_form_book_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
}

.second_form_book {
	flex-basis: 24%;
	margin: 5px;
	border: 1px solid #ddd;
	padding: 10px;
	box-sizing: border-box;
	cursor: pointer;
	box-shadow: 0px 4px 6px rgb(0 0 0 / 13%);
	border-radius: 20px;
	background: white;
	position: relative;
	min-height: 250px;
}

.book-image {
	width: 100%;
	/* Make the image fill the container */
	/* height: 200px; */

	/* Maintain aspect ratio */
	border-radius: 8px;
}

.book-details {
	margin-top: 5px;
}

.book-title {
	font-size: 16px;
	margin-bottom: 10px;
	word-wrap: break-word;
	text-align: center;
}

.book-price {
	font-size: 13px;
	color: #333;
	font-weight: 700;
	position: absolute;
	bottom: -25px;
	left: -3px;
}

.book-description {
	font-size: 13px;
	line-height: 1.5;
}

.sec_wiz_2 .title,
#page_service_selects .title {
	padding: 10px;
	border-radius: 10px;
	text-align: center;
	border: solid 2px #2196f3;
	box-shadow: 2px 2px 5px lightgray;
	color: #2196f3;
	background: white;
}

.checkoutPage {
	padding: 10px 0;
	margin: auto;
}

a {
	text-decoration: none;
}

.info_icon {
    bottom: 6px;
    font-size: 12px;
    width: 17px;
    border: solid 1px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: 6px;
    color: #0e70be;
    opacity: 0.4;
    z-index: 2;
}

.info_box {
	position: absolute;
	width: 100%;
	font-size: 10px;
	background: #085d83;
	border-radius: 10px;
	padding: 5px;
	z-index: 1;
	top: 0;
	color: white;
	border: solid 1px var(--primary);
	/* box-shadow: 2px 2px 2px grey; */
	display: block;
	left: 0;
	transition: 1s ease-in;
	bottom: 0;
	opacity: .9;
	overflow: auto;
	animation: popeffect .5s ease-in-out;
}

/* .second_form_book:hover .info_box {
    display: block !important;
} */
.info_icon:hover .info_box {
	display: block !important;
}

.nomp {
	margin: 0 !important;
	padding: 0 !important;
}

.review_page1 label {
	font-size: 11px;
}

@keyframes popeffect {
	0% {

		top: 50px;
		display: none;
	}

	100% {
		width: auto;
		height: auto;
		top: 0;
		left: 0;
		display: block;
	}
}


@media (max-width: 1475px) {
	.second_form_book {
		/* width: calc(30% - 20px); */
		flex-basis: 27.5%;
		margin: 10px;
		border: 1px solid #ddd;
		padding: 10px;
		box-sizing: border-box;
		cursor: pointer;
	}

	#sec_wiz_1 {
		width: 40%;
		height: 240px !important;
		padding: 20px 0;
	}

	.book-title {
		font-size: 12px;
	}

	.review_page {
		width: 90% !important;
		margin: auto;
		margin-top: -70px !important;
		margin-bottom: 200px;
		background-color: #fff;
		padding: 10px;
		border-radius: 10px;
		box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	}

	.review_page input[type="text"],
	.review_page input[type="email"],
	.review_page input[type="password"],
	.review_page textarea {
		width: 100%;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 5px;
	}

	.sidebar_tabs {
		display: none !important;
	}

	.booking_tabs {
		display: block !important;
	}

	.section {
		width: 60%;
		margin: auto;
		margin-top: 100px;
		/* margin-bottom: 200px; */
		background-color: #ffffffe3;
		padding: 80px;
		border-radius: 10px;
		box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	}

	#employees .option li {
		padding: 10px 15px !important;
		padding-left: 35px !important;
		height: 42px;
		display: inline-block;
		width: auto;
		font-size: 15px !important;
		margin-right: 10px;
		cursor: pointer;
		transition: all 0.2s ease-in;
		border: 1px solid #ccc;
		border-radius: 25px;
		min-width: 120px;
	}

	#employees .option li .icon {
		position: absolute;
		top: 4px;
		left: 8px !important;
		width: 21px !important;
		height: 32px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.progressbar_userpannel .progressbarproviders {
		position: relative;
		width: 97%;
		top: 11px !important;
	}

	#sec_wiz_2,
	.sec_wiz_2 {
		width: 97%;
		margin: auto;
		margin-top: 20px !important;
		height: auto;
		margin-bottom: 20px;
		background: #ffffffe3;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	}

	#sec_wiz_2,
	.sec_wiz_2 {
		width: 60%;
	}

	.products .item_wrapper {
		position: relative;
		display: inline-block;
		width: 100% !important;
		margin: auto;
		white-space: normal;
		padding: 4px !important;
	}

	.review_page1 {
		width: 80% !important;
	}
}

@media (max-width: 768px) {



	.progressbar_userpannel .progressbarproviders {
		position: relative;
		width: 97%;
		top: 5px !important;
	}
	#sec_wiz_1 {
		width: 40%;
		height: 240px !important;
		padding: 20px 0;
		/* zoom: 0.7; */
	}
	/* .sections {
		width: 97% !important;
		margin: auto;
		margin-top: 1px !important;
		margin-bottom: 200px;
		background-color: #fff;
		padding: 10px;
		border-radius: 10px;
		box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	} */
	.booking_tabs {
		display: block !important;
	}

	.review_page1 {
		width: 90% !important;
		zoom: .7 !important;
	}

	.booking_tabs li {
		width: 75px !important;
		font-size: 13px;
		margin-top: -10px !important;
	}

	.centerFlex img {
		width: 100%;
	}

	.section {
		width: 97% !important;
		margin: auto;
		zoom: 1;
		margin-top: 35px !important;
		/* margin-bottom: 200px; */
		background-color: #fff;
		padding: 10px;
		border-radius: 10px;
		box-shadow: 2px 2px 15px -5px rgb(0 0 0 / 35%);
	}

	#sec_wiz_4 {
		zoom: 0.7;
	}

	.second_form_book {
        width: calc(50% - 20px);
        flex-basis: 46%;
        margin: 4px;
        border: 1px solid #ddd;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        min-height: 200px;
    }
	h2.book-title{
		min-height: 50px;
	}

	#sec_wiz_2,
	.sec_wiz_2 {
		width: 90%;
		zoom: 0.8;
	}

	.time_options li {
		font-size: 12px;
	}

	.review_page1 .eye_button {
		left: 75% !important;
	}



}