* {
	margin: 0;
	box-sizing: border-box;
	padding: 0;
	/* font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol"; */
	font-family: "Comfortaa", sans-serif;
}
.h {
	font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
}

body {
	background-color: #fff !important;
}

@import "./Components/assets/css/bootstrap-datepicker.min.css";
@import "./Components/assets/css/custom.css";
@import "./Components/assets/css/material.css";
@import "./Components/assets/css/media.css";

@import "./Admins/CSS/custom.css";
@import "./Admins/CSS/material.css";
@import "./Admins/CSS/media.css";
@import "./Admins/CSS/table.css";

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");



/* //destop mode */
@import "./Components/assets/css/destop.css";
@import "./Components/assets/css/resposive.css";